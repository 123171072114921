<template>
	<div class="login-c pointer">
		<!-- 弹窗中的X关闭按钮 -->
		<span v-if="closeStaus" class="el-icon-close closeicon" @click="closeMo"></span>
		<div class="box log-c">
			<div class="title">聚材通，让买水泥更简单</div>
			<div class="flex-row">
				<!-- 扫码登陆 -->
				<div class="leftBox">
					<div v-show="scanCodeStatus != 1" style="margin-top:68px;">
						<div class="flex-row-center-center">
							<div class="logind flex-row-center-center">
								<div v-if="scanCodeStatus == 4" class="scan-code-error flex-column-center-center">
									<div class="font-size17 font-weight700 font-color-fff">二维码已失效</div>
									<div @click="getLoginQRCode"
										class="refresh-btn pointer margin-t-15 flex-row-center-center font-color-fff font-size14 font-weight700">
										刷新</div>
								</div>
								<div class="logind-1 flex-row-center-center">
									<div id="qrCodeDivID"></div>
								</div>
								<div class="logind-2">
									<img :src="img2" style="width: 100%" />
								</div>
							</div>
						</div>
						<div class="logind-3">
							<p style="font-size:16px;font-weight: 700;">打开<span class="logind-4">聚材通平台</span>App</p>
							<p>在[首页]页面顶部打开扫一扫</p>
							<p>如扫码异常请下载最新版客户端</p>
						</div>
					</div>
					<!-- 扫码登陆成功 -->
                     	<div v-show="scanCodeStatus == 1" class="scan-code-success flex-column-center-center" style="margin-top: 75px">
                     		<div style="font-size: 17px;color:#000000;font-weight:700">扫码登录</div>
                     		<div class="iconfont icon-queren" style="margin:20px 0 29px 0"></div>
                     		<div class="scan-code-success-title font-size18">扫描成功!</div>
                     		<div class="font-size13 font-color-999">请勿刷新本页面，按手机提示操作!</div>
                     	</div>
				</div>
				<!-- 扫码登陆结束 -->
				<div style="width:1px;height:375px;background: #E4E4E4;"></div>
				<!-- 账号，验证码登录 -->
				<div class="rightBox">
					<div style="margin:20px 35px 0 35px">
						<div style="display: flex;margin-bottom: 28px;">
							<span class="loginp" :class="codestatus?'spanC':''" @click="clickTab(1)">账号登录</span>
							<span class="loginp margin-l-15" :class="pastatus?'spanC':''"
								@click="clickTab(2)">验证码登录</span>
						</div>
						<div class="logind-5">
							<el-form class="forminp" :model="loginForm" :rules="rules" ref="loginForm"
								style="position: relative;">
								<el-alert :title='showtoasttitle' type="warning" show-icon
									style="position: absolute;top: 50%;margin-top: -15px; z-index: 999;"
									class="el-message--warning " v-if="showtoast">
								</el-alert>
								<el-form-item v-if="codestatus" prop="adminLogin">
									<el-input v-model="loginForm.adminLogin" placeholder="输入账号" maxlength="11"
										autocomplete="off" class="testf" />
								</el-form-item>
								<el-form-item v-if="!codestatus" prop="mobile">
									<el-input v-model="loginForm.mobile" maxlength="11" placeholder="输入手机号"
										autocomplete="off" class="testf" />
								</el-form-item>
								<el-form-item v-if="!codestatus" prop="verCode">
									<div v-show="innerVisibles"
										style="position: absolute;bottom: -147px;z-index: 999;padding: 3px;background-color: rgb(255, 255, 255);left: -1px;width: 324px;">
										<i class="el-icon-circle-close"
											style="position: absolute;right: 14px;top: 6px;color: rgb(255 255 255);font-size: 26px;z-index: 2;background: #525252;border-radius: 50%;"
											@click="closeYanzheng"></i>
										<changeGraphic1 @messageData='messageData'></changeGraphic1>
									</div>
									<el-input v-model="loginForm.verCode" placeholder="输入验证码" class="testf2">
										<template slot="append">
											<div @click="getCode" v-if="verCodeText" class="pointer"
												style="color: #0173fe">获取验证码</div>
											<div v-if="!verCodeText" style="color: #0173fe">重新获取（{{verCodeNub}})秒</div>
										</template>
									</el-input>
								</el-form-item>
								<el-form-item prop="password" v-if="!pastatus">
									<el-input v-model.trim="loginForm.password" auto-complete="new-password"
										maxlength="20" type="password" placeholder="输入登录密码" class="testf"
										:show-password="true" @keyup.enter.native="login('loginForm')"></el-input>
								</el-form-item>
								<el-form-item>
									<div>
										<el-button v-if="!codestatus" class="btn" round
											@click="verCodeLogin('loginForm')" :loading="loadingtrue">登录/注册</el-button>
										<el-button v-if="codestatus" class="btn" round @click="login('loginForm')"
											:loading="loadingtrue">登录</el-button>
									</div>
								</el-form-item>
							</el-form>
						</div>

						<div class="logind-6">
							<div class="logind-6p" style="line-height: 20px;" v-if="!codestatus">
								未注册手机验证后自动登录，注册即代表同意
								<span v-for="(item,index) in zhuceData" :key>
									<span v-if="index != 0">、</span><span style="cursor: pointer;"
										class="color-theme font-size12 lh21"
										@click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
								</span>
							</div>
							<div class="logind-6p" style="float: right;" v-if="!pastatus" @click="goforget">
								忘记密码
							</div>
						</div>
					    <div class="logind-7">
					    	<div class="logind-8">
					    		<span style="margin-right: 55px"> 其他登录方式 </span>
					    		<span style="margin-right: 20px; margin-left: 23px">
					    			<span class="iconfont icon-login_ " id="imgsty"></span>
					    			<a style="text-decoration: none;" href="https://open.weixin.qq.com/connect/qrconnect?appid=wx72a73bbf41f30b82&redirect_uri=https://www.xjjcjc.com/&response_type=code&scope=snsapi_login&#wechat_redirect">微信登录</a>
					    		</span>
					    		<span style="margin-left: 39px">
					    			<span class="iconfont icon-qq" id="imgsty2"></span>
					    			QQ登录
					    		</span>
					    	</div>
					    </div>
					</div>
				</div>
				<!-- 账号，验证码登录结束 -->
			</div>
			<!-- 立即注册按钮 -->
			<div class="flex-row">
				<div style="width:371px;height:49px"></div>
				<div class="logind-9">
					<span class="logind-9-sp " @click="regest">立即注册</span>
				</div>
			</div>
		</div>
		<agreementDialog :iContents='iContent' ref="areDialog" /> 
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import router from "../../router";
	import QRCode from 'qrcodejs2'
	import agreementDialog from '@/components/agreementDialog';
	import changeGraphic1 from '@/components/changeGraphic1';
	export default {
		name: "StoreLogin",
		components: {
			agreementDialog,
			changeGraphic1
		},
		props: {
			closeStaus: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				//隐私协议
				iContenthtml: "",
				fTitle: "",
				// 倒计时循环
				expiringInterval: null,
				// 二维码状态 0等待扫码,1已扫码等待确认，2拒绝，3成功,4过期或不存在
				scanCodeStatus: 0,
				// 二维码 数据
				scanCodeToken: "",
				loadingtrue: false,
				register: "false",
				zhuceData: [], //协议列表
				innerVisibles: false,
				iContent: '',
				showtoast: false,
				iContents: '',
				dialogVisible: false, //验证码弹窗图形验证
				msg: false, //获取验证码滑动成功还是失败
				showtoasttitle: "",
				//数据绑定
				loginForm: {
					adminLogin: "", //账号
					mobile: "", //手机号
					password: "",
					verCode: "",
				},
				verCodeText: true, // 是否显示验证码字段
				verCodeNub: "", // 验证码读数
				verCodeTimer: null, // 验证码计时器变量
				//表单验证规则
				rules: {
					adminLogin: [{
						required: true,
						message: "请输入账号",
						trigger: "blur",
					}],
					mobile: [{
							required: true,
							message: "请输入正确的手机号码",
							trigger: "blur",
						},
						{
							// required: true,
							pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
							message: '请输入正确的手机号码',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: "请输入密码",
							trigger: "blur",
						},
						// {
						// 	min: 6,
						// 	max: 20,
						// 	message: '请输入6-20位数字或字母的密码',
						// 	trigger: 'change'
						// }
						{
							// required: true,
							pattern: /^([^\u4e00-\u9fa5]){6,20}$/,
							message: '请输入6-20位的密码',
							trigger: 'blur'
						}
					],
					verCode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur",
					}]
				},
				codestatus: true,
				pastatus: false,
				status: true,
				Fstatus: false,
				img1: require("@/assets/bgimg/u68.png"),
				img2: require("@/assets/bgimg/s9.png"),
				img3: require("@/assets/u20.png"),
				agreTitle: '', //协议标题
			};
		},
		computed: {
			// 获取vuex中的showLogin，控制登录组件是否显示
			isLogin: {
				get() {
					return this.$store.getters.getShowLogin;
				},
				set(val) {
					this.setShowLogin(val);
				},
			},
		},

		destroyed() {
			// 移除倒计时循环
			clearInterval(this.expiringInterval);
		},
		mounted() {
			this.getLoginQRCode(); //获取二维码
			this.getRegAgreement()
		},
		methods: {
			...mapActions(["setUser", "setShowLogin"]),
			goforget() {
				console.log('点击事件')
				this.isLogin = false;
				this.$router.push('/forget');
			},
			//接收验证码传来的参数
			messageData(a) {
				if (a) {
					var _this = this;
					setTimeout(function() {
						_this.innerVisibles = false;
						_this.msg = a
						_this.getCode();
						console.log('子传递值，已经变成true')
					}, 500);
				}
			},
			// 关闭验证码
			closeYanzheng() {
				this.innerVisibles = false
			},
			// 解除二维码 相关状态
			clearQRCodeFun() {
				clearInterval(this.expiringInterval);
				this.scanCodeStatus = 0;
			},
			//展示协议
			showAgreeDia(a) {
				console.log(a)
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			// 关闭图形验证码弹窗
			handleClose() {
				this.dialogVisible = false;
			},
			//获取隐私协议
			getRegAgreement() {
				this.pageLoading = true;
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/agreement-history/get-by-register-list"
				).then(
					(result) => {
						console.log(result.obj.items)
						this.zhuceData = result.obj.items
						console.log(this.zhuceData)
						this.pageLoading = false;

						//console.log(result,"zhucexieyi ");
						// result.obj.items.forEach((item)=>{
						// 	if(item.fTitle == "隐私协议"){

						// 		this.iContenthtml = item.fContent;
						// 		this.agreTitle = item.fTitle;
						// 	}
						// })
					},
					(rej) => {}
				);
			},
			// 获取登录二维码
			getLoginQRCode() {
				// 二维码状态重置
				this.scanCodeStatus = 0;
				this.ApiRequestPost("api/mall/sys/account/get-login-code", {}).then(
					res => {
						// token：二维码数据、expiringDate：二维码过期时间
						this.scanCodeToken = res.token;
						let data = {
							token: res.token,
							FType: 1
						}
						this.createQrCode(data);
						this.loginQRcodeCountDown(res.expiringDate);
					},
					error => {}
				);
			},
			// 生成二维码
			createQrCode(qrCoderContext) {
				let refQRID = 'qrCodeDivID'
				let RQDpcument = document.getElementById(refQRID)
				// 重置元素内容
				RQDpcument.innerHTML = ""
				this.QRCode = new QRCode(RQDpcument, {
					width: 140,
					height: 140,
					text: JSON.stringify(qrCoderContext), // 二维码地址 TODO 
					colorDark: "#000", // 二维码颜色
					colorLight: "#fff" // 二维码背景色
				});
			},
			// 二维码过期时间倒计时
			loginQRcodeCountDown(expiringDate) {
				let _this = this;
				// 获取倒计时时间秒数
				let timeRemaining = new Date(expiringDate).getSeconds();
				// 进入轮循  倒计时
				this.expiringInterval = setInterval(function() {
					timeRemaining = timeRemaining - 1;
					_this.intervalForWaitResult();
					// 倒计时剩余时间为 0 时 移除倒计时循环
					if (timeRemaining == 0) {
						// 倒计时时间为0 后 将二维码状态置为 4：过期
						_this.scanCodeStatus = 4;
						clearInterval(_this.expiringInterval);
					}
				}, 1000);
			},
			// 开启轮询 等待结果
			intervalForWaitResult() {
				// this.scanCodeToken 二维码 token数据
				// TODO 确认此接口是否需要传参

				this.ApiRequestPostNOMess("api/mall/sys/account/polling-login", {
					token: this.scanCodeToken
				}).then(
					res => {
						console.log('扫码接口结果')
						console.log(res.status)
						// Status（0等待扫码,1已扫码等待确认，2拒绝，3成功,4过期或不存在）
						this.scanCodeStatus = res.status;
						switch (res.status) {
							case 2:
								// 拒绝
								this.tipsInfo(res.message)
								// 结束倒计时轮回
								clearInterval(this.expiringInterval);
								this.scanCodeStatus = 4;
								break;
							case 3:
								// 当Status等于3的时候 可以直接使用AccessToken字段作为用户登录凭证
								this.tipsSuccess(res.message)
								// 保存token
								// window.sessionStorage.setItem("token", res.accessToken);
								// localStorage.setItem("token", res.accessToken);
								// this.getUserInfo();
								var that = this

								this.getUserInfo(res.accessToken, function() {
									localStorage.setItem("loginResult", true);
									that.$router.push("/home");
								});
								// 导航至/home
								// this.$router.push("/home");
								break;
							case 4:
								// 过期
								this.tipsInfo(res.message)
								// 结束倒计时轮回
								clearInterval(this.expiringInterval);
								break;
						}
					},
					error => {}
				);
			},
			regest() {

				this.isLogin = false;
				this.$router.push("/register");
			},
			clickTab(type) {
				if (type == 1) {
					this.codestatus = true;
					this.pastatus = false;
				}
				if (type == 2) {
					this.pastatus = true;
					this.codestatus = false;
				}
				if (this.$refs.loginForm) {
					this.$nextTick(function() {
						this.$refs.loginForm.clearValidate();
					})
				}
				this.loginForm.mobile = ""
				this.loginForm.verCode = ""
				this.loginForm.password = ""
				this.loginForm.adminLogin = ""

			},
			// 验证码登录
			verCodeLogin(formName) {
				var routers = this.$route.path;
				console.log(routers);
				if (routers.indexOf('login') != -1) {
					//login页面登录
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.loadingtrue = true;
							this.ApiRequestPostNOMess("/api/mall/sys/account/phone-login", {
								phoneNumber: this.loginForm.mobile,
								token: this.loginForm.verCode,
								fAppTypeId: this.fAppTypeID
							}).then(
								(res) => {
									console.log(res, "登录失败效果测试")

									//保存token
									// window.sessionStorage.setItem("token", res.accessToken);
									// localStorage.setItem("token", res.accessToken);

									// this.getUserInfo();

									let _this = this;
									_this.getUserInfo(res.accessToken, function() {
										_this.loginForm.mobile = ""
										_this.loginForm.verCode = ""
										_this.isLogin = false;
										_this.loadingtrue = false;
										localStorage.setItem("loginResult", true);
										_this.$router.push("/home");
									});

								},
								(error) => {
									console.log(error)
									this.showtoastmessage(error.error.message)
									this.loadingtrue = false;
								});
						} else {
							console.log('error submit!!');

							return false;
						}
					});
				} else {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.loadingtrue = true;
							this.ApiRequestPost("/api/mall/sys/account/phone-login", {
								phoneNumber: this.loginForm.mobile,
								token: this.loginForm.verCode,
								fAppTypeId: this.fAppTypeID
							}).then(
								(res) => {
									//保存token
									// window.sessionStorage.setItem("token", res.accessToken);
									// localStorage.setItem("token", res.accessToken);
									// this.isLogin = false;
									// this.loadingtrue = false;
									// this.loginForm.mobile = ""
									// this.loginForm.verCode = ""
									// this.$router.push("/home");
									// this.getUserInfo();
									// this.getUserInfo(res.accessToken, function() {
									// 		this.$router.push("/home");										
									// });
									let _this = this;
									_this.getUserInfo(res.accessToken, function() {
										_this.loginForm.mobile = ""
										_this.loginForm.verCode = ""
										_this.isLogin = false;
										_this.loadingtrue = false;
										localStorage.setItem("loginResult", true);
										_this.$router.push("/home");
									});


									// 导航至/home

								},
								(error) => {

									this.loadingtrue = false;
								});
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				}



				/* var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if (this.loginForm.mobile == '' || this.loginForm.mobile == undefined) {
					this.tipsInfo('请输入手机号')
					return;
				}
				if (!reg_tel.test(this.loginForm.mobile)) {
					this.tipsInfo('请输入正确的手机号码')
					return;
				}
				if (this.loginForm.verCode == '' || this.loginForm.verCode == undefined) {
					this.tipsInfo('请输入验证码')
					return;
				} */


			},
			showtoastmessage(e) {
				let that = this;
				if (that.showtoast == true) {
					return;
				}
				that.showtoasttitle = e;

				console.log(that.showtoasttitle)
				that.showtoast = true
				setTimeout(function() {
					that.showtoast = false;
				}, 1000)
			},

			// 获取验证码
			getCode() {
				let _this = this;
				if (_this.loginForm.mobile == "") {
					this.showtoastmessage("请输入手机号码")
					return;
				}
				if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(_this.loginForm.mobile))) {
					this.showtoastmessage("手机号码格式错误")
					return;
				}
				if (this.msg == false) {
					this.innerVisibles = true;
					return;
				}
				if (this.msg == true) {
					this.dialogVisible = false
					const TIME_COUNT = 60;
					if (!_this.verCodeTimer) {
						// TODO 临时用接口
						_this.ApiRequestPostNOMess("api/mall/sys/account/send-phone-code", {
								phone: _this.loginForm.mobile,
							})
							.then(
								(res) => {
									if (res.obj.status) {
										// _this.tipsSuccess("验证码发送成功");
										_this.verCodeNub = TIME_COUNT;
										_this.verCodeText = false;
										_this.verCodeTimer = setInterval(() => {
											if (_this.verCodeNub > 0 && _this.verCodeNub <= TIME_COUNT) {
												_this.verCodeNub--;
											} else {
												_this.verCodeText = true;
												clearInterval(_this.verCodeTimer);
												_this.verCodeTimer = null;
												_this.msg = false
											}
										}, 1000);

									} else {
										_this.msg = false
										_this.tipsInfo('验证码发送频繁，请稍后再试！')
									}

								},
								(rej) => {
									_this.msg = false
									let errorMessage = ""
									errorMessage = rej.error.message
									if (errorMessage.indexOf('发送频率过高') == -1) {
										_this.tipsInfo(errorMessage)
									} else {
										_this.tipsInfo('验证码发送频繁，请稍后再试！')
									}

								}
							);
					}
				}




			},
			//账号登录
			login(formName) {
				var routers = this.$route.path;
				console.log(routers);
				if (routers.indexOf('login') != -1) {
					//登录页登录

					console.log('登录页登录')
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.loadingtrue = true;
							this.ApiRequestPostNOMess("api/mall/sys/account/login", {
								loginName: this.loginForm.adminLogin,
								password: this.loginForm.password,
							}).then(
								(res) => {
									// this.isLogin = false;
									// this.loadingtrue = false;
									//保存token
									// window.sessionStorage.setItem("token", res.accessToken);
									// localStorage.setItem("token", res.accessToken);
									// this.getUserInfo();
									// this.loginForm.adminLogin = ""
									// this.loginForm.password = ""
									// this.getUserInfo(res.accessToken, function() {
									// 		this.$router.push("/home");										
									// });
									let _this = this;
									_this.getUserInfo(res.accessToken, function() {
										_this.loginForm.adminLogin = ""
										_this.loginForm.password = ""
										_this.isLogin = false;
										_this.loadingtrue = false;
										localStorage.setItem("loginResult", true);
										_this.$router.push("/home");
									});
								},
								(error) => {
									this.showtoastmessage(error.error.message)
									this.loadingtrue = false;
								});
						} else {
							console.log('error submit!!');
							return false;
						}
					});



				} else {
					// 首页登录
					console.log('首页登录')
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.loadingtrue = true;
							this.ApiRequestPost("api/mall/sys/account/login", {
								loginName: this.loginForm.adminLogin,
								password: this.loginForm.password,
							}).then(
								(res) => {
									console.log("r555es", res);
									this.isLogin = false;
									this.loadingtrue = false;
									//保存token
									// window.sessionStorage.setItem("token", res.accessToken);
									// this.loginForm.adminLogin = ""
									// this.loginForm.password = ""
									// localStorage.setItem("token", res.accessToken);
									// 			this.getUserInfo(res.accessToken, function() {
									// 		this.$router.push("/home");

									// });
									let _this = this;

									_this.getUserInfo(res.accessToken, function() {
										_this.loginForm.adminLogin = ""
										_this.loginForm.password = ""
										_this.isLogin = false;
										_this.loadingtrue = false;
										localStorage.setItem("loginResult", true);
										_this.$router.push("/home");
									});

									// 导航至/home

								},
								(error) => {
									this.loadingtrue = false;
								});
						} else {
							console.log('error submit!!');
							return false;
						}
					});

				}


				/* this.$refs[formName].validate((valid) => {
					if (!valid) {
						return;
					}
					
				}); */
			},
			closeMo() {
				this.isLogin = false;
			},
			getUserInfo(token, callback) {
				sessionStorage.setItem("token", token);
				localStorage.setItem("token", token);
				this.ApiRequestPostNOMess(
					"/api/mall/ebcustomer/baseinfo/get-current-customer-user-info"
				).then(
					(result) => {
						this.$store.commit("setUserInfo", result.obj);
						this.setUser(result.obj);
						sessionStorage.setItem("setUserInfo", JSON.stringify(result.obj));
						if (result.obj.fVerifiedStatus) {
							this.getCarCuont();

						}
						if (typeof callback == 'function') {
							callback();
						}
					},
					(rej) => {}
				);
			},

			getCarCuont() {
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/shopping-cart/get-shopping-cart-count"
				).then(
					(result) => {
						this.$store.commit("setCarCount", result.obj.number);
					},
					(rej) => {}
				);
			},
			WXLogin() {
				var WX_url =
					"https://open.weixin.qq.com/connect/qrconnect?appid=wx0e9254619070ba33&redirect_uri=https://www.henghesoft.com&response_type=code&scope=snsapi_login&#wechat_redirect";
			},

		},
	};
</script>

<style>
	.icon-zhanghaodenglu,
	.icon-erweima {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.forminp {
		width: 100%;
	}

	.el-alert--warning.is-light {
		background-color: #FFF3EC !important;
		border: 1px #FF6600 solid !important;
		border-color: #FF6600 !important;
		font-size: 22px !important;
		color: #FF6600 !important;
		line-height: 16px;
	}

	.el-alert--warning .el-alert__content .el-alert__title {
		font-size: 16px;
	}


	.testf .el-input__inner {
		border-radius: 20px !important;
		width: 100% !important;
		height: 40px !important;
		background-color: rgba(246, 246, 246, 1);
		border: 0;
	}

	.testf2 .el-input__inner {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		height: 40px !important;
		width: 100% !important;
		background-color: rgba(246, 246, 246, 1);
		border: 0;
	}

	.testf2,
	.el-input-group--prepend .el-input__inner,
	.el-input-group__append {
		border: 0;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	#storeLogin .el-dialog--center .el-dialog__body {
		padding: 0;
	}

	#storeLogin .el-dialog__body {
		padding: 0;
	}

	#storeLogin .el-dialog__header {
		display: none;
	}
</style>
<style lang="scss" scoped="scoped">
	.box {
		height: 475px;
		border-radius: 20px;
	}

	.closeicon {
		color: #fff;
		font-size: 32px;
		top: -6px;
		position: absolute;
		right: -46px;
	}

	.title {
		line-height: 50px;
		border-radius: 20px 20px 0 0;
		background: #eff4fd;
		font-size: 18px;
		font-weight: 700;
		color: #000000;
		text-align: center;
	}

	.leftBox {
		width: 371px;
		height: 100%px;
	}

	.logind {
		position: relative;
	}

	.scan-code-error {
		position: absolute;
		width: 277px;
		height: 162px;
		background-color: rgba($color: #000000, $alpha: 0.5);
	}

	.refresh-btn {
		width: 120px;
		height: 36px;
		background-color: #0173fe;
	}

	.logind-1 {
		padding: 6px;
		margin-right: 22px;
		width: 148px;
		height: 148px;
		border: 1px solid #e4e4e4;
	}

	.logind-2 {
		width: 93px;
		height: 162px;
	}

	.logind-3 {
		margin-top: 39px;
		font-size: 13px;
		text-align: center;
		line-height: 24px;
		color: #000000;
	}

	.rightBox {
		width: 401px;
		height: 100%;

	}

	.loginp {
		font-size: 16px;
		font-weight: 400;
		cursor: pointer;
		color: #333;
		height: 32px;
	}

	.spanC {
		border-bottom: 4px solid #0173fe;
		font-weight: 700 !important;
		font-size: 16px !important;
	}
	.logind-5 {
		.el-form-item {
			margin-bottom: 20px;
		}
	}
	.logind-6p {
		
		color: #999999;
	}
	.logind-7 {
		font-size: 13px;
		margin-bottom: 15px;
	}
	
	.logind-8 {
		border-top: 1px solid #e4e4e4;
		padding-top: 18px;
		color: #999999;
		height:27px;
	}
	

	//立即注册按钮
	.logind-9 {
		width: 401px;
		height: 49px;
		line-height: 49px;
		background: #f8f9fe;
		display: flex;
		justify-content: center;
		border-radius: 0 0 20px 0;
	}

	.logind-9-sp {
		font-size: 13px;
		color: #0173fe;
		cursor: pointer;
	}




	/* ===================================================================== */















	.scan-code-success {
		height: 260px;
	}

	.scan-code-success-title {
		margin-top: 26px;
		margin-bottom: 10px;
	}

	.icon-queren {
		color: #60C63E;
		font-size: 122px;
	}

	.logind-6 {
		overflow: hidden;
		font-size: 12px;
		text-align: left;
		margin-left: 13px;
		margin-right: 13px;
		// margin-bottom: 15px;
		height: 50px;
	}



	
	




	.floatd {
		float: right;
	}



	.scan-code-box {}











	.logind-4 {
		color: #0099ff;
		margin-left: 3px;
		margin-right: 3px;
	}


	.btn {
		font-size: 15px;
		width: 100%;
		background-color: rgba(1, 115, 254, 1);
		color: #fff;
	}

	.m11 {
		margin-top: 11px;
	}

	.login-t {
		font-weight: 700;
		font-size: 18px;
		line-height: 80px;
		height: 80px;
		// min-width: 1100px;
		// max-width: 1100px;
		margin: 0 auto;

	}

	.loginh {
		// min-height: 480px;
		// background: #ffd67d url(../assets/login.png) no-repeat center;
		// background-size: 807px 480px;
	}

	.login-c {}

	.log-c {
		// width: 322px;
		// height: 355px;
		// // float: right;
		background-color: #fff;
		position: relative;
		border-radius: 20px;
	}

	.imgsty {
		height: 100% !important;
	}

	#imgsty {

		position: absolute;
		width: 27px;
		margin-left: -33px;
		margin-top: -5px;
		font-size: 26px;
		color: #88a448;
	}

	#imgsty2 {
		position: absolute;
		width: 27px;
		margin-left: -33px;
		margin-top: -4px;
		font-size: 26px;
		color: #43aded;
	}

	.margin-b-22 {
		margin-bottom: 25px;
	}
</style>