<template>

		<div class="bussiness">
			<leftMenus></leftMenus>
			<router-view />			
		</div>
  
</template>

<script>
	import LeftMenus from  '@/views/business/LeftMenu'
	export default{ 
		components:{
			LeftMenus
		}
	}
</script>

<style scoped>
	.bussiness{
		width:1110px;
		margin: 0 auto;
		display: flex;
	}
</style>
