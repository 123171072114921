<!-- RouteBox
  路由嵌套封装

-->

<template>
  <router-view  />
</template>

<script>
export default {
  name: "RouteBox"
}
</script>

<style lang="scss" scoped>
</style>
