import { render, staticRenderFns } from "./RouteBox.vue?vue&type=template&id=de8996c4&scoped=true&"
import script from "./RouteBox.vue?vue&type=script&lang=js&"
export * from "./RouteBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de8996c4",
  null
  
)

export default component.exports