let items = [{
		id: '1',
		title: '我的',
		child: [{
				id: '11001',
				title: '我的积分',
				url: '/PersonalContent/MyPoints'

			},
			{
				id: '11002',
				title: '会员权益',
				url: '/MemberCenter'
			},
			{
				id: '11003',
				title: '问卷调查',
				url: '/QuestionnaireInvestigation'
			},
		]
	}, {
		id: '2',
		title: '账户设置',
		child: [{
				id: '22001',
				title: '我的资料',
				url: '/PersonalContent/myStuff'
			},
			{
				id: '22002',
				title: '登录密码',
				url: '/PersonalContent/LoginPassword'
			},
			{
				id: '22003',
				title: '账号关联',
				url: '/PersonalContent/AccountConnection'
			},
			// {
			// 	id: '22004',
			// 	title: '支付密码',
			// 	url: '/PersonalContent/PaymentPassword'
			// },
			{
				id: '22005',
				title: '注销账户',
				url: '/PersonalContent/CancelAccount'
			},
			{
				id: '22006',
				title: '修改密码（临时）',
				url: '/PersonalContent/ChangePassword'
			},
			{
				id: '22007',
				title: '设置支付密码',
				url: '/PersonalContent/paymentPassword'
			},
		]
	},
	// {
	// 	id: '3',
	// 	title: '钱包',
	// 	child: [{
	// 			id: '1',
	// 			title: '现金钱包',
	// 			url: '/PersonalContent/MoneyDash'
	// 		},
	// 		{
	// 			id: '2',
	// 			title: '票据钱包',
	// 			url: '/PersonalContent/billWallet'
	// 		},
	// 		{
	// 			id: '3',
	// 			title: '账服通钱包',
	// 			url: '/PersonalContent/AccountPay'
	// 		}
	// 	]
	// },
	{
		id: '4',
		title: '信息维护',
		child: [{
				id: '44001',
				title: '我的地址',
				url: '/PersonalContent/myAddress'
			},
			{
				id: '44002',
				title: '我的车辆',
				url: '/PersonalContent/myVehicle'
			},

			{
				id: '44003',
				title: '发票信息',
				url: '/PersonalContent/InvoiceInformation'
			},
			{
				id: '44004',
				title: '我的员工',
				url: '/PersonalContent/MyPerson',
			},
			{
				id: '44005',
				title: '管理岗位',
				url: '/PersonalContent/ManagementPosition',
			}
		]
	}
];


let footerItem = [{
		id: '1',
		title: '购物指南',
		child: [{
				id: '1',
				title: '注册认证',
				url: "/HelpCenter/RegistrationCertification"
			},
			{
				id: '2',
				title: '购买流程',
				url: "/HelpCenter/PurchaseProcess"
			},
			{
				id: '3',
				title: '在线支付',
				url: "/HelpCenter/OnlinePayment"
			},
			{
				id: '4',
				title: '提货方式',
				url: "/HelpCenter/DeliveryDetails"
			},
		]

	},
	{
		id: '2',
		title: '帮助中心',
		child: [{
				id: '1',
				title: '会员权益',
				url: "/HelpCenter/MemberGrowth"
			},
			{
				id: '2',
				title: '开通店铺',
				url: "/OpenShop"
			},
			{
				id: '3',
				title: '常见问题',
				url: "/HelpCenter/frmhelpctrregistproblem"
			},
			{
				id: '4',
				title: '视频教学',
				url: "/HelpCenter/TeachingVideo"
			},
			{
				id: '5',
				title: '申请承运',
				url: "/CarrierRegistration"
			},
			// {
			// 	id: '4',
			// 	title: '教学视频',
			// 	url: ""
			// },
		]

	},
	{
		id: '3',
		title: '规则协议',
		child: [{
				id: '2',
				title: '注册协议',
				url: "xieyitanchuang"
			},
			{
				id: '3',
				title: '隐私声明',
				url: "xieyitanchuang"
			},
			{
				id: '7',
				title: '交易规则',
				url: "xieyitanchuang"
			},
			{
				id: '4',
				title: '知识产权',
				url: "xieyitanchuang"
			},
			{
				id: '5',
				title: '店铺协议',
				url: "xieyitanchuang"
				// url: '/PersonalContent/HelpCenter'
			},
			{
				id: '6',
				title: '积分规则',
				url: "/HelpCenter/IntegralRule"
			},
		]

	},
	{
		id: '4',
		title: '售后服务',
		child: [{
				id: '1',
				title: '售后政策',
				url: "/HelpCenter/HelpCenter"
			},
			{
				id: '2',
				title: '检验申请',
				url: "/HelpCenter/HelpCenter"
			},
			{
				id: '3',
				title: '发票递送',
				url: "/HelpCenter/HelpCenter"
			},
            {
            	id: '4',
            	title: '线上合同',
            	url: "/ElectronicContract"
            }
		]

	},
	{
		id: '5',
		title: '关于我们',
		child: [{
				id: '1',
				title: '关于聚材',
				url: '/HelpCenter/HelpCenter'
			},
			// {
			// 	id: '2',
			// 	title: '资质下载',
			// },
			{
				id: '3',
				title: '联系我们',
				url: '/HelpCenter/HelpCenter'
			}

		]

	},

];
let pro = [{
		id: '1',
		title: '水泥',
		child: [{
				childNmae: '水泥品种',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			},
			{
				childNmae: '水泥品种2',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			}
		]


	},
	{
		id: '2',
		title: '商砼',
		child: [{
				childNmae: '水泥品种',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			},
			{
				childNmae: '水泥品种2',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			}
		]

	},
	{
		id: '3',
		title: '熟料',
		child: [{
				childNmae: '水泥品种',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			},
			{
				childNmae: '水泥品种2',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			}
		]

	},
	{
		id: '4',
		title: '矿粉',
		child: [{
				childNmae: '水泥品种',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			},
			{
				childNmae: '水泥品种2',
				child: [{
						id: '1',
						title: '全部'
					},
					{
						id: '2',
						title: '普硅'
					},
					{
						id: '3',
						title: '高抗硫'
					},
					{
						id: '4',
						title: '低碱'
					},
					{
						id: '5',
						title: 'P.C'
					},
					{
						id: '6',
						title: 'P.O'
					},
					{
						id: '7',
						title: '熟料'
					}
				]
			}
		]

	}
];


let parmas = [{
	id: '1',
	id2: '2',
	content: [{
		id: '1',
		title: '1111'
	}, {
		id: '1',
		title: '1111'
	}]
}, {
	id: '2',
	id2: '2',
	content: [{
		id: '1',
		title: '1111'
	}, {
		id: '1',
		title: '1111'
	}]
}];
let arr = [{
		id: '1',
		id2: '2',
		content: [{
			id: '1',
			title: '1111'
		}, {
			id: '1',
			title: '1111'
		}]
	}, {
		id: '1',
		id2: '2',
		content: [{
			id: '1',
			title: '1111'
		}, {
			id: '1',
			title: '1111'
		}]
	},
	{
		id: '3',
		id2: '4',
		content: [{
			id: '6',
			title: '1111'
		}, {
			id: '5',
			title: '1111'
		}]
	},
	{
		id: '3',
		id2: '4',
		content: [{
				id: '6',
				title: '1111'
			}, {
				id: '5',
				title: '1111'
			},
			{
				id: '7',
				title: '1111'
			}
		]
	}
];
let navItems = [{
		id: '1',
		title: '办公用纸',
		isSelect: false
	}, {
		id: '2',
		title: '办公耗材',
		isSelect: false
	},
	{
		id: '3',
		title: '办公用纸',
		isSelect: false
	}, {
		id: '4',
		title: '办公耗材',
		isSelect: false
	},
	{
		id: '5',
		title: '办公用纸',
		isSelect: false
	}, {
		id: '6',
		title: '办公耗材',
		isSelect: false
	},
	{
		id: '7',
		title: '办公用纸',
		isSelect: false
	}, {
		id: '8',
		title: '办公耗材',
		isSelect: false
	},
	{
		id: '9',
		title: '办公用纸',
		isSelect: false
	}, {
		id: '10',
		title: '办公耗材',
		isSelect: false
	}, {
		id: '11',
		title: '办公用纸',
		isSelect: false
	}
];
let cardOneList = [{
		img: require('@/assets/imgs/GoldCoinMall/erji.png'),
		title: '耳机名耳机名耳机名耳机名',
		price: 200
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/saodiji.png'),
		title: '扫地机扫地机扫地机扫地机',
		price: 250
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/boliguo.png'),
		title: '玻璃锅玻璃锅玻璃锅玻璃锅',
		price: 999
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/erji.png'),
		title: '耳机名耳机名耳机名耳机名',
		price: 200
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/saodiji.png'),
		title: '扫地机扫地机扫地机扫地机',
		price: 250
	}
];
let cardThreeList = [{
		img: require('@/assets/imgs/GoldCoinMall/phone.png'),
		title: '新品上市 vivo Y70s 8GB+128GB',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/chawan.png'),
		title: '新品上市 泰光银楼 999足银茶碗',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/xiangji.png'),
		title: '新品上市 尼康相机D75024-120',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/dainshiji.png'),
		title: '新品上市 TCL雷鸟 50英寸4k我是专用',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/phone.png'),
		title: '新品上市 vivo Y70s 8GB+128GB',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/chawan.png'),
		title: '新品上市 泰光银楼 999足银茶碗',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/xiangji.png'),
		title: '新品上市 尼康相机D75024-120',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/dainshiji.png'),
		title: '新品上市 TCL雷鸟 50英寸4k我是专用',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/xiangji.png'),
		title: '新品上市 尼康相机D75024-120',
		newPrice: 532
	},
	{
		img: require('@/assets/imgs/GoldCoinMall/dainshiji.png'),
		title: '新品上市 TCL雷鸟 50英寸4k我是专用',
		newPrice: 532
	}
];
export default {
	items,
	footerItem,
	pro,
	parmas,
	arr,
	cardOneList,
	navItems,
	cardThreeList


}
