import RouteBox from '@/components/RouteBox'
import Index from '@/views/business/LeftMenuIndex.vue'
export default {
	path: '/businessme',
	name: 'businessme',
	component: Index,
	meta: {
		title: "业务"
	},
	children: [{
			path: 'AllOrder',
			name: 'AllOrder',
			component: () => import('@/views/business/Order/AllOrder.vue'),
			meta: {
				title: "全部订单",
			}
		},
		{
			path: 'PaymentOrderList',
			name: 'PaymentOrderList',
			component: () => import('@/views/business/Order/PaymentOrder.vue'),
			meta: {
				title: "待支付订单",
			}
		},
		{
			path: 'DispatchOrder',
			name: 'DispatchOrder',
			component: () => import('@/views/business/Order/DispatchOrder.vue'),
			meta: {
				title: "待派车订单",
			}
		},
		{
			path: 'RefundOrder',
			name: 'RefundOrder',
			component: () => import('@/views/business/Order/RefundOrder.vue'),
			meta: {
				title: "可退款订单",
			}
		},
		{
			path: 'OverdueOrder',
			name: 'OverdueOrder',
			component: () => import('@/views/business/Order/OverdueOrder.vue'),
			meta: {
				title: "已过期订单",
			}
		},
		{
			path: 'EvaluationOrder',
			name: 'EvaluationOrder',
			component: () => import('@/views/business/Order/EvaluationOrder.vue'),
			meta: {
				title: "待评价订单",
			}
		},
		{
			path: 'CompletedOrder',
			name: 'CompletedOrder',
			component: () => import('@/views/business/Order/CompletedOrder.vue'),
			meta: {
				title: "已完成订单",
			}
		},
		{
			path: 'AllSendCar',
			name: 'AllSendCar',
			component: () => import('@/views/business/SendCar/AllSendCar.vue'),
			meta: {
				title: "全部派车单",
			},
		},
		{
			path: 'AlreadySendCar',
			name: 'AlreadySendCar',
			component: () => import('@/views/business/SendCar/AlreadySendCar.vue'),
			meta: {
				title: "已派车",
			},
		},
		// {
		// 	path: 'mergeSendCar',
		// 	name: 'mergeSendCar',
		// 	component: () => import('@/views/business/SendCar/mergeSendCar.vue'),
		// 	meta: {
		// 		title: "合单派车",
		// 	},
		// },
		// {
		// 	path: 'combinedOrder',
		// 	name: 'combinedOrder',
		// 	component: () => import('@/views/business/mergeSendCar/combinedOrder.vue'),
		// 	meta: {
		// 		title: "提交合单派车",
		// 	},
		// },
		{
			path: 'AlreadyEnterFactory',
			name: 'AlreadyEnterFactory',
			component: () => import('@/views/business/SendCar/AlreadyEnterFactory.vue'),
			meta: {
				title: "已进厂",
			},
		},
		{
			path: 'AlreadyLoading',
			name: 'AlreadyLoading',
			component: () => import('@/views/business/SendCar/AlreadyLoading.vue'),
			meta: {
				title: "已装车",
			},
		},
		{
			path: 'AlreadyOutFactory',
			name: 'AlreadyOutFactory',
			component: () => import('@/views/business/SendCar/AlreadyOutFactory.vue'),
			meta: {
				title: "已出厂",
			},
		},
		{
			path: 'ExpiredSendCar',
			name: 'ExpiredSendCar',
			component: () => import('@/views/business/SendCar/ExpiredSendCar.vue'),
			meta: {
				title: "已过期",
			},
		},
		{
			path: 'CanReplenished',
			name: 'CanReplenished',
			component: () => import('@/views/business/SendCar/CanReplenished.vue'),
			meta: {
				title: "可补量",
			},
			children: []
		},
		{
			path: 'CanPayReplenished',
			name: 'CanPayReplenished',
			component: () => import('@/views/business/SendCar/CanPayReplenished.vue'),
			meta: {
				title: "可支付补量",
			},
		}, {
			path: 'RechargeWallet',
			name: 'RechargeWallet',
			component: () => import('@/views/business/Wallet/RechargeWallet.vue'),
			meta: {
				title: "现金账服通充值提现",
			}
		},
		{
			path: 'jytRechargeWallet',
			name: 'jytRechargeWallet',
			component: () => import('@/views/business/Wallet/jytRechargeWallet.vue'),
			meta: {
				title: "交易通提现",
			}
		},
		{
			path: 'createRechargeOrder',
			name: 'createRechargeOrder',
			component: () => import('@/views/HomePage/Task/createRechargeOrder.vue'),
			meta: {
				title: "现金账服通充值提现",
			}
		},
		{
			path: 'ReachCreditAndTicket',
			name: 'ReachCreditAndTicket',
			component: () => import('@/views/business/Wallet/ReachCreditAndTicket.vue'),
			meta: {
				title: "票据提现授信还款",
			}
		},
		{
			path: 'RechSuccess',
			name: 'RechSuccess',
			component: () => import('@/views/business/Wallet/RechSuccess.vue'),
			meta: {
				title: "提现退款充值成功",
			}
		},
		{
			path: 'WithdrawalWallet',
			name: 'WithdrawalWallet',
			component: () => import('@/views/business/Wallet/WithdrawalWallet.vue'),
			meta: {
				title: "钱包提现",
			}
		},
		{
			path: 'SelectRechargeList',
			name: 'SelectRechargeList',
			component: () => import('@/views/business/Wallet/SelectRechargeList.vue'),
			meta: {
				title: "选择充值单",
			}
		},
		{
			path: 'TicketDetailed',
			name: 'TicketDetailed',
			component: () => import('@/views/business/Wallet/TicketDetailed.vue'),
			meta: {
				title: "票据明细",
			}
		},
		{
			path: 'RechargeTicket',
			name: 'RechargeTicket',
			component: () => import('@/views/business/Wallet/RechargeTicket.vue'),
			meta: {
				title: "票据充值",
			}
		},
		{
			path: 'RechargeTicketForm',
			name: 'RechargeTicketForm',
			component: () => import('@/views/business/Wallet/RechargeTicketForm.vue'),
			meta: {
				title: "新增票据充值表单",
			}
		},
		{
			path: 'TickteShop',
			name: 'TickteShop',
			component: () => import('@/views/business/Wallet/TickteShop.vue'),
			meta: {
				title: "票据店铺",
			}
		},
		{
			path: 'ConsolidationTicket',
			name: 'ConsolidationTicket',
			component: () => import('@/views/business/Wallet/ConsolidationTicket.vue'),
			meta: {
				title: "票据合并",
			}
		},
		{
			path: 'RefundTicket',
			name: 'RefundTicket',
			component: () => import('@/views/business/Wallet/RefundTicket.vue'),
			meta: {
				title: "票据退款",
			}
		},
		{
			path: 'billsBill',
			name: 'billsBill',
			component: () => import('@/views/business/billsBill/billsBill.vue'),
			meta: {
				title: "票据钱包",
			}
		},
		{
			path: 'receiptBill',
			name: 'receiptBill',
			component: () => import('@/views/business/receiptBill/receiptBill.vue'),
			meta: {
				title: "收货账单",
			}
		},
		{
			path: 'capitalBill',
			name: 'capitalBill',
			component: () => import('@/views/business/capitalBill/capitalBill.vue'),
			meta: {
				title: "资金账单",
			}
		},
		{
			path: 'InspectionOrder',
			name: 'InspectionOrder',
			component: () => import('@/views/business/other/InspectionOrder.vue'),
			meta: {
				title: "检验单申请",
			}
		},
		{
			path: 'winningRecord',
			name: 'winningRecord',
			component: () => import('@/views/business/other/winningRecord.vue'),
			meta: {
				title: "中奖记录",
			}
		},
		{
			path: 'addInsOrder',
			name: 'addInsOrder',
			component: () => import('@/views/business/other/addInsOrder.vue'),
			meta: {
				title: "新增检验单申请",
			}
		},
		{
			path: 'editInsOrder',
			name: 'editInsOrder',
			component: () => import('@/views/business/other/editInsOrder.vue'),
			meta: {
				title: "编辑检验单申请",
			}
		},
		{
			path: 'MoneyDash',
			name: 'MoneyDash',
			component: () => import('@/views/myCenter/MoneyDash.vue'),
			meta: {
				title: "现金钱包",
			}
		},
		{
			path: 'individualUsersBindingCard',
			name: 'individualUsersBindingCard',
			component: () => import('@/views/myCenter/MyAccount/IndividualUsersBindingCard.vue'),
			meta: {
				title: "个人用户绑卡",
			},

		},
		{
			path: 'bindingJYT',
			name: 'bindingJYT',
			component: () => import('@/views/myCenter/JYTpay/bindingJYT.vue'),
			meta: {
				title: "开通交易通",
			},
		
		},
		{
			path: 'JYTallAccount',
			name: 'JYTallAccount',
			component: () => import('@/views/myCenter/JYTpay/JYTallAccount.vue'),
			meta: {
				title: "交易通",
			},
		},
		{
			path: 'JYTRecordList',
			name: 'JYTRecordList',
			component: () => import('@/views/myCenter/JYTpay/JYTRecordList.vue'),
			meta: {
				title: "资料变更记录",
			},
		
		},
		{
			path: 'JYTUpload',
			name: 'JYTUpload',
			component: () => import('@/views/myCenter/JYTpay/JYTUpload.vue'),
			meta: {
				title: "资料变更",
			},
		
		},{
			path: 'JYTSubmitResults',
			name: 'JYTSubmitResults',
			component: () => import('@/views/myCenter/JYTpay/JYTSubmitResults.vue'),
			meta: {
				title: "变更结果",
			},
		
		},
		{
			path: 'JYTwallet',
			name: 'JYTwallet',
			component: () => import('@/views/myCenter/JYTwallet.vue'),
			meta: {
				title: "交易通钱包",
			},
		
		},
		{
			path: 'JYTwallet',
			name: 'JYTwallet',
			component: () => import('@/views/myCenter/JYTwallet.vue'),
			meta: {
				title: "交易通钱包",
			},
		
		},
		{
			path: 'enterpriseUsersBindingCard',
			name: 'enterpriseUsersBindingCard',
			component: () => import('@/views/myCenter/MyAccount/EnterpriseUsersBindingCard.vue'),
			meta: {
				title: "企业用户绑卡",
			},

		},
		{
			path: 'OpenBillWallet',
			name: 'OpenBillWallet',
			component: () => import('@/views/myCenter/MyAccount/OpenBillWallet.vue'),
			meta: {
				title: "开通票据钱包",
			},
		
		},
		{
			path: 'allAccount',
			name: 'allAccount',
			component: () => import('@/views/myCenter/MyAccount/allAccount.vue'),
			meta: {
				title: "银行卡",
			},

		},
		{
			path: 'WalletBankCard',
			name: 'WalletBankCard',
			component: () => import('@/views/myCenter/wallet/WalletBankCard.vue'),
			meta: {
				title: "现金钱包银行卡",
			},

		},
		{
			path: 'bandCard',
			name: 'bandCard',
			component: () => import('@/views/myCenter/MyAccount/bandCard.vue'),
			meta: {
				title: "个人用户绑卡",
			},

		},
		{
			path: 'billWallet',
			name: 'billWallet',
			component: () => import('@/views/myCenter/wallet/BillWallet.vue'),
			meta: {
				title: "票据钱包",
			}
		},
		{
			path: 'entityAccount',
			name: 'entityAccount',
			component: () => import('@/views/myCenter/wallet/EntityAccount.vue'),
			meta: {
				title: "票据钱包",
			}
		},
		{
			path: 'AccountPay',
			name: 'AccountPay',
			component: () => import('@/views/myCenter/AccountPay.vue'),
			meta: {
				title: "账服通钱包",
			}
		},
		{
			path: 'JYTwallet',
			name: 'JYTwallet',
			component: () => import('@/views/myCenter/JYTwallet.vue'),
			meta: {
				title: "交易通钱包",
			}
		},
		{
			path: 'GoldBill',
			name: 'GoldBill',
			component: () => import('@/views/GoldMall/GoldBill.vue'),
			meta: {
				title: "金币账单",
			}
		},
		{
			path: 'pointsBill',
			name: 'pointsBill',
			component: () => import('@/views/myCenter/my/pointsBill.vue'),
			meta: {
				title: "积分账单",
			}
		},
		{
			path: 'CreditBill',
			name: 'CreditBill',
			component: () => import('@/views/GoldMall/CreditBill.vue'),
			meta: {
				title: "授信账单",
			}
		},
		{
			path: 'OrderBill',
			name: 'OrderBill',
			component: () => import('@/views/GoldMall/OrderBill.vue'),
			meta: {
				title: "订单统计",
			}
		},
		{
			path: 'CreditLine',
			name: 'CreditLine',
			component: () => import('@/views/GoldMall/CreditLine.vue'),
			meta: {
				title: "授信额度",
			}
		},
		{
			path: 'CreditLineAdd',
			name: 'CreditLineAdd',
			component: () => import('@/views/GoldMall/CreditLineAdd.vue'),
			meta: {
				title: "授信申请",
			}
		},
		{
			path: 'CreditLineEdit',
			name: 'CreditLineEdit',
			component: () => import('@/views/GoldMall/CreditLineEdit.vue'),
			meta: {
				title: "编辑授信申请",
			}
		},
		{
			path: 'loanInvoice',
			name: 'loanInvoice',
			component: () => import('@/views/business/other/loanInvoice.vue'),
			meta: {
				title: "贷款发票",
			}
		},
	]

}
