<template>
	<div>
		<div id="header-title" class="help-center-breadcrumb flex-row-align-center padding-l-15 margin-b-10 font-color-666">
			关于我们 > 企业简介
		</div>
		<div>
		<iframe id="iframe" src="https://www.xjjcjc.com/helpcenter/introDuction.html" width="953px" height="1150px" scrolling="auto" ></iframe>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';
	export default {
		data() {
			return {
			
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.$bus.$on('type', function({fContentPath,fPath}) {
				document.all.iframe.src=fContentPath+"?time="+new Date().getTime();
				document.querySelector("#header-title").innerHTML=fPath	
				});
		  },
		methods: {}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.shopping-process-text {
		line-height: 36px;
	}

	.help-center-breadcrumb {
		height: 42px;
		background-color: #ebeefc;
		margin-top: 25px;
		width: 949px;
	}
</style>
