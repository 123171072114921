import Vue from 'vue'
import Router from 'vue-router'
import personal from './personal'
import business from './business.js'
import helpCenter from './helpCenter.js'
import homepage from './HomePage/homepage.js'
import SalesNetwork from './SalesNetwork.js'
import RouteBox from '@/components/RouteBox'


Vue.use(Router)

const routes = [{
		path: '/',
		name: 'RouteBox',
		redirect: '/home',
		component: () => import('../views/Main.vue'),
		children: [{
				path: '/home',
				component: () => import('../views/Home.vue'),
				meta: {
					keepAlive: true
				}
			},
			{
				path: 'luckMain',
				name: 'luckMain',
				component: () => import('../views/luckMain.vue'),
				meta: {
					title: "抽奖",
				}
			},
			{
				path: 'luckMainPlatform',
				name: 'luckMainPlatform',
				component: () => import('../views/luckMainPlatform.vue'),
				meta: {
					title: "平台抽奖",
				},
			},
			{
				path: 'businessBuy',
				name: 'businessBuy',
				component: () => import('../views/businessBuy/businessBuy.vue'),
				meta: {
					title: "企业购",
				},
			},
			{
				path: 'pointPurchase',
				name: 'pointPurchase',
				component: () => import('../views/pointPurchase/pointPurchase.vue'),
				meta: {
					title: "积分购",
				},
			},
      {
				path: 'cement',
				name: 'cement',
				component: () => import('../views/cement/cement.vue'),
				meta: {
					title: "特水展页",
				},
			},
			{
				path: 'buyingPricelist',
				name: 'buyingPricelist',
				component: () => import('../views/businessBuy/buyingPricelist.vue'),
				meta: {
					title: "议价申请列表",
				},
			},
			{
				path: 'applicationPricetab',
				name: 'applicationPricetab',
				component: () => import('../views/businessBuy/applicationPricetab.vue'),
				meta: {
					title: "申请议价",
				},
			},
			{
				path: 'buyingPriceDetails',
				name: "buyingPriceDetails",
				component: () => import('../views/businessBuy/buyingPriceDetails.vue'),
				meta: {
					title: "议价详情",
				}
			},
			{
				name: 'Task',
				path: '/Task',
				component: () => import('../views/HomePage/Task/Task.vue'),
				meta: {
					title: "任务",
				}
			},
			{
				name: 'NewCreateTaskOrder',
				path: '/NewCreateTaskOrder',
				component: () => import('../views/HomePage/Task/NewCreateTaskOrder.vue'),
				meta: {
					title: "订单任务",
				}
			},
			{
				path: '/AccountPay',
				name: 'AccountPay',
				component: () => import('../views/myCenter/AccountPay.vue')
			},
			// {
			// 	path: '/helpCenter',
			// 	name: 'HelpCenter',
			// 	component: () => import('../views/myCenter/HelpCenter.vue')
			// },
			{
				path: '/404error',
				name: '404error',
				component: () => import('../views/erro/404.vue')
			},
			{
				path: '/403error',
				name: '403error',
				component: () => import('../views/erro/403.vue')
			},
			{
				path: '/500error',
				name: '500error',
				component: () => import('../views/erro/500.vue')
			},
			{
				path: '/nocerror',
				name: 'nocerror',
				component: () => import('../views/erro/noc.vue')
			},
			{
				path: '/about',
				name: 'About',
				component: () => import('../views/About.vue'),
				children: [{
					path: '/aboutJuCai',
					name: 'AboutJuCai',
					component: () => import('../views/AboutJuCai.vue')
				}]
			},
			{
				path: '/aboutJuCai',
				name: 'AboutJuCai',
				component: () => import('../views/AboutJuCai.vue')
			},
			{
				path: '/couPon',
				name: 'CouPon',
				component: () => import('../views/myCenter/CouPon.vue')
			},
			{
				path: '/goldCoinLotteryTicket',
				name: 'goldCoinLotteryTicket',
				component: () => import('../views/myCenter/goldCoinLotteryTicket.vue'),
			},
			{
				path: '/myFocus',
				name: 'MyFocus',
				component: () => import('../views/myCenter/MyFocus.vue')
			},
			{
				path: '/goods/details',
				name: 'Details',
				component: () => import('../views/Details.vue')
			},
			{
				path: '/FunctionPage',
				name: 'FunctionPage',
				component: () => import('../views/FunctionPage.vue'),
				meta: {
					title: "功能页面",
				}
			},
			{
				path: '/LimitedTimeActivities',
				name: 'LimitedTimeActivities',
				component: () => import('../views/LimitedTimeActivities.vue'),
				meta: {
					title: "限时活动",
				}
			},
			{
				path: '/Seckkill',
				name: 'Seckkill',
				component: () => import('../views/Seckkill.vue'),
				meta: {
					title: "秒杀活动",
				}
			},
			// {
			// 	path: '/pointLimitedTimeActivities',
			// 	name: 'pointLimitedTimeActivities',
			// 	component: () => import('../views/pointLimitedTimeActivities.vue'),
			// 	meta: {
			// 		title: "积分购",
			// 	}
			// },
			{
				path: '/LimitedActivities',
				name: 'LimitedActivities',
				component: () => import('../views/LimitedActivities.vue'),
				meta: {
					title: "活动",
				}
			},
      {
				path: '/LimitedShopActivities',
				name: 'LimitedShopActivities',
				component: () => import('../views/LimitedShopActivities.vue'),
				meta: {
					title: "活动",
				}
			},
			{
				path: '/SignIn',
				name: 'SignIn',
				component: () => import('../views/SignIn.vue'),
				meta: {
					title: "签到",
				}
			},
			{
				path: '/shoppingCart',
				name: 'ShoppingCart',
				component: RouteBox,
				meta: {
					title: "购物车",
				},
				children: [{
						path: '/',
						component: () => import('../views/ShoppingCart.vue'),
					},
					{
						path: 'fillOrder',
						name: 'FillOrder',
						component: () => import('../views/FillOrder.vue'),
						meta: {
							title: "填写订单",
						},

					},
					{
						path: 'seckillFillOrder',
						name: 'seckillFillOrder',
						component: () => import('../views/seckillFillOrder.vue'),
						meta: {
							title: "填写订单",
						},
					
					},
					{
						path: 'FillOrderEdit',
						name: 'FillOrderEdit',
						component: () => import('../views/FillOrderEdit.vue'),
						meta: {
							title: "填写订单",
						},

					},
					{
						path: 'seckillFillOrderEdit',
						name: 'seckillFillOrderEdit',
						component: () => import('../views/seckillFillOrderEdit.vue'),
						meta: {
							title: "填写订单",
						},
					
					},
					{
						path: 'createTaskOrder',
						name: 'createTaskOrder',
						component: () => import('../views/HomePage/Task/createTaskOrder.vue'),
						meta: {
							title: "填写订单",
						},

					},
					{
						path: 'FillOrderB2B',
						name: 'FillOrderB2B',
						component: () => import('@/views/HomePage/Goods/FillOrderB2B.vue'),
						meta: {
							title: "填写订单",
						},
					},
					{
						path: 'FillOrderEditB2B',
						name: 'FillOrderEditB2B',
						component: () => import('@/views/HomePage/Goods/FillOrderEditB2B.vue'),
						meta: {
							title: "填写订单",
						},
					},
					{
						path: 'NewfillOrder',
						name: 'NewFillOrder',
						component: () => import('../views/NewFillOrder.vue'),
						meta: {
							title: "填写订单",
						},

					},
					{
						path: 'NewFillOrderEdit',
						name: 'NewFillOrderEdit',
						component: () => import('../views/NewFillOrderEdit.vue'),
						meta: {
							title: "填写订单",
						},

					},
					{
						path: 'NewFillOrderB2B',
						name: 'NewFillOrderB2B',
						component: () => import('@/views/HomePage/Goods/NewFillOrderB2B.vue'),
						meta: {
							title: "填写订单",
						},
					},
					{
						path: 'NewFillOrderEditB2B',
						name: 'NewFillOrderEditB2B',
						component: () => import('@/views/HomePage/Goods/NewFillOrderEditB2B.vue'),
						meta: {
							title: "填写订单",
						},
					}
				],
			},
			{
				path: 'OrderDetails',
				name: 'OrderDetails',
				component: () => import('@/views/OrderDetails.vue'),
				meta: {
					title: "订单详情",
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: 'VehicleDispatchingIncrease',
				name: 'VehicleDispatchingIncrease',
				component: () => import('@/views/business/SendCar/VehicleDispatchingIncrease.vue'),
				meta: {
					title: "派车补量单",
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/collect',
				name: 'Collect',
				component: () => import('../views/Collect.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/memberCenter',
				name: 'MemberCenter',
				component: () => import('../views/MemberCenter.vue'), //会员中心
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/QuestionnaireInvestigation',
				name: 'QuestionnaireInvestigation',
				component: () => import('../views/QuestionnaireInvestigation.vue'), //问卷调查
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/couponCollection',
				name: 'CouponCollection',
				component: () => import('../views/CouponCollection.vue'), //领券中心
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
      {
				path: '/CouponCollectionShop',
				name: 'CouponCollectionShop',
				component: () => import('../views/CouponCollectionShop.vue'), //店铺领券中心
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/news',
				name: 'News',
				component: () => import('../views/myCenter/News.vue'), //消息中心
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/order',
				name: 'Order',
				component: () => import('../views/Order.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/confirmOrder',
				name: 'ConfirmOrder',
				component: () => import('../views/ConfirmOrder.vue'),
				meta: {
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: '/testupload',
				name: 'testupload',
				component: () => import('../views/testupload.vue'),

			},
			{
				path: '/business',
				name: 'business',
				component: RouteBox,
				children: [{
					path: '/',
					component: () => import('../views/business/BusinessIndex.vue'),
				}, ]
			},
			{
				path: 'sendCar',
				name: 'SendCar',
				component: () => import('../views/business/SendCar.vue'), //派车
				meta: {
					title: "派车",
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: 'SendCarSuccess',
				name: 'SendCarSuccess',
				component: () => import('../views/business/SendCarSuccess.vue'), //派车成功
				meta: {
					title: "派车成功"
				}
			},
			{
				path: 'SendCarDetail',
				name: 'SendCarDetail',
				component: () => import('../views/business/SendCar/SendCarDetail.vue'),
				meta: {
					title: "派车单详情",
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: 'OrderSendCarDetail',
				name: 'OrderSendCarDetail',
				component: () => import('../views/business/SendCar/OrderSendCarDetail.vue'),
				meta: {
					title: "订单派车详情",
					requireAuth: false // 需要验证登录状态
				}
			},
			{
				path: 'OpenShop',
				name: 'OpenShop',
				component: RouteBox,
				meta: {
					title: "申请开店",
				},
				children: [{
						path: '/',
						component: () => import('@/views/HomePage/OpenShop/OpenShop.vue'),
					},
					{
						path: 'OpenShopForm',
						name: 'OpenShopForm',
						component: () => import('@/views/HomePage/OpenShop/OpenShopForm.vue'),
						meta: {
							title: "开非自营工厂店表单",
						}
					},
					{
						path: 'OpenDistributorShopForm',
						name: 'OpenDistributorShopForm',
						component: () => import('@/views/HomePage/OpenShop/OpenDistributorShopForm.vue'),
						meta: {
							title: "开经销商店表单",
						}
					},
				],
			},
			{
				path: 'CarrierRegistration',
				name: 'CarrierRegistration',
				component: () => import('@/views/HomePage/CarrierRegistration/CarrierRegistration.vue'),
				meta: {
					title: "承运商注册",
				}
			},
			{
				path: 'ElectronicContract',
				name: 'ElectronicContract',
				component: () => import('@/views/HomePage/ElectronicContract/ElectronicContract.vue'),
				meta: {
					title: "线上合同",
				}
			},
			{
				path: 'UnderReview',
				name: 'UnderReview',
				component: () => import('@/views/myCenter/UnderReview.vue'),
				meta: {
					title: "完善资料审核中",
				}
			},
			{
				path: 'bargainPrice',
				name: 'bargainPrice',
				component: () => import('@/views/myCenter/bargainPrice.vue'),
				meta: {
					title: "议价中",
				}
			},
			{
				path: 'ShopList',
				name: 'ShopList',
				component: () => import('@/views/HomePage/Goods/ShopList.vue'),
				meta: {
					title: "店铺列表",
					keepAlive: true
				},
			},
			{
				path: 'SeckillBilList',
				name: 'SeckillBilList',
				component: () => import('@/views/HomePage/SeckillBil/SeckillBilList.vue'),
				meta: {
					title: "限时秒杀",
				},
			},
			personal,
			homepage,
			SalesNetwork,
			business,
			helpCenter
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../Login.vue'),

	}, {
		path: '/register',
		name: 'register',
		component: () => import('../Register.vue'),

	},
	{
		path: '/changephone',
		name: 'changephone',
		component: () => import('../changephone.vue'),

	},
	{
		path: '/changephoneitem',
		name: 'changephoneitem',
		component: () => import('../changephoneitem.vue'),

	},
	{
		// 忘记密码
		path: '/forget',
		name: 'forget',
		component: () => import('../forget.vue'),

	},
	{
		path: '/paymentOrder',
		name: 'PaymentOrder',
		component: () => import('../views/PaymentOrder.vue'),
		meta: {
			title: "支付订单",
			requireAuth: false // 需要验证登录状态
		}
	},
	{
		path: '/paymentResults',
		name: 'PaymentResults',
		component: () => import('../views/PaymentResults.vue'),
		meta: {
			title: "支付结果",
			requireAuth: false // 需要验证登录状态
		}
	},
	{
		path: '/PaymentReturn',
		name: 'PaymentReturn',
		component: () => import('../views/PaymentReturn.vue'),
		meta: {
			title: "支付结果",
			requireAuth: false // 需要验证登录状态
		}
	},
	{
		path: '/storeList',
		name: 'storeList',
		component: () => import('../StoreList.vue'),
		meta: {
			title: "店铺",
			keepAlive: false,
		},
	}, {
		path: '/goldMall',
		name: 'GoldMall',
		meta: {
			title: "金币商城",
			keepAlive: true
		},
		component: () => import('../views/GoldMall/GoldMallMain.vue'),
		children: [{
				path: '/',
				component: () => import('../views/GoldMall/GoldMall.vue'),
			meta: {
				keepAlive: true
			},
			}, {
				path: 'goldGoods',
				name: 'goldGoods',
				component: () => import('../views/GoldMall/GoldGoods.vue'),
				meta: {
					title: "商品列表",
					keepAlive: true
				}
			},
			{
				path: 'goodsDetails',
				component: () => import('../views/GoldMall/goodsDetails.vue'),
				meta: {
					title: "商品详情",
				}
			},
			{
				path: 'goldShoppingCart',
				name: 'goldShoppingCart',
				component: () => import('../views/GoldMall/GoldShoppingCart.vue'),
				meta: {
					title: "金币商城购物车",
				}
			},
			{
				path: 'GoldOrder',
				name: 'GoldOrder',
				component: () => import('../views/GoldMall/GoldOrder.vue'),
				meta: {
					title: "金币订单",
				}
			},
			{
				path: 'FillOrderGoldMall',
				name: 'FillOrderGoldMall',
				component: () => import('@/views/GoldMall/FillOrderGoldMall.vue'),
				meta: {
					title: "填写订单",
				},
			},
			{
				path: 'GoldOrderDetail',
				name: 'GoldOrderDetail',
				component: () => import('@/views/GoldMall/GoldOrderDetail.vue'),
				meta: {
					title: "金币订单详情",
				},
			},
			{
				path: 'ExchangeG',
				name: 'ExchangeG',
				component: () => import('@/views/GoldMall/ExchangeG.vue'),
				meta: {
					title: "兑换金币",
				},
			},
			// {
			// 	path: 'GoldBill',
			// 	name: 'GoldBill',
			// 	component: () => import('../views/GoldMall/GoldBill.vue'),
			// 	meta: {
			// 		title: "金币账单",
			// 	}
			// },
			{
				path: 'GoldMallCollection',
				name: 'GoldMallCollection',
				component: () => import('../views/GoldMall/GoldMallCollection.vue'),
				meta: {
					title: "商品收藏",
				}
			}

		]
	}
]


const router = new Router({

	routes
})

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

export default router
