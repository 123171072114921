<template>
	<div :class="themeClass" style="margin-top: 10px">
		<div v-if="ShowPerfect" class="message-box margin-t-10 margin-b-10 flex-column-justify-center">
			<el-row class="flex-row">
				<i class="el-icon-s-opportunity flex-row message-box-i font-size17"></i>
				<div class="attestat_box flex-row-space-between-center">
					<div class="font-size14 flex-row">
						为保证绿色网络环境，请及时进行实名认证
					</div>
					<div class="perfert_btn flex-row pointer" @click="Allperfect">
						<span class="margin0-auto font-size13">去完善</span>
					</div>
				</div>
			</el-row>
		</div>
		<div class="top-suff flex-row">
			<!-- 左边 -->
			<div class="suff-left">
				<el-row class="jucai-pinyin font-color-fff font-weight700">
					JU CAI TONG
				</el-row>
				<div class="position-r">
					<!-- 头像 -->
					<div class="user">
						<el-avatar :size="80" :src="url"></el-avatar>
					</div>
				</div>
				<!-- 未登录 -->
				<div v-if="JSON.stringify(getUserInfo) == '{}'" class="useName font-size18 font-color-333 font-weight700 flex-row-center-center"
				 @click="toLogin">
					登录/注册
				</div>
				<!-- 用户名 -->
				<div v-else class="useName font-size18 font-color-333 font-weight700 flex-row-center-center">
					<div>
						<div>{{userInfo.fUserName==userInfo.customerName?userInfo.fUserName:userInfo.fUserName+'-'+userInfo.customerName}}</div>
						<div class="flex-row-center-center margin-t-5">
							<div v-if="userInfo.fUserName!=''" class="font-size12 text-center font-color-fff font-weight400">
								<div v-if="userInfo.fVerifiedStatus" class="renzheng_btn flex-row-center-center">已认证</div>
								<div v-else class="no_zr_btn flex-row-center-center">未认证</div>
							</div>
						</div>
					</div>
				</div>
				<div class="leftBottom">
					<div style='display: flex;justify-content: space-between;align-items: center;' class="margin-l-15 margin-r-15 padding-t-20"
					>
						<div class="flex-row "  @click="gomemberCenter">
							<i class="iconfont icon-huiyuan1" style="color:#F1C685;font-weight: 700;font-size:24px"></i>
							<div class="font-size16 font-weight700" style="color: #F5D59D;">会员权益</div>
						</div>
						<i class="iconfont icon-you2 iconYou"  @click="gomemberCenter"></i>		
					</div>
					<div style="color: #F5D59D;display: flex;justify-content: space-between;align-items: center;font-size:;" 
					class="margin-l-15 margin-r-15 margin-t-8">
						<div>当前等级&nbsp;{{ 'LV' + userInfo.gradeId + '-' +  userInfo.gradeName }}</div>
						<div>
							会员值&nbsp;<span class="font-size18">{{customerMoreInfo.fGrowupNum}}</span>	
						</div>
					</div>

				</div>
			</div>
			<!-- 左边结束 -->
			<div class="suff-row">
				<div class="margin-l-35 margin-r-35 " style="display: flex;justify-content: space-between;align-items: center;
				border-bottom: 1px solid #f2f2f2;margin-top:35px;">
					 <div style="width:50%;text-align: center;border-right: 1px solid #f2f2f2;"  @click="toGoldMall">
					 	<img src="@/assets/imgs/jinbi.png" width="35px" height="35px" />
						<div style="font-size: 30px;color: #171B38;margin:10px 0 10px 0;font-family: 'DingTalk-JinBuTi';">{{customerMoreInfo.fEndBalance}}</div>
						<div style="font-size: 16px;color: #8794A7;" class="margin-b-20">金币</div>
					 </div>
					 <div style="width:50%;text-align: center;" @click="gomyPoints">
					 	<img src="@/assets/imgs/jifen.png" width="35px" height="35px" />
					 	<div style="font-size: 30px;color: #171B38;margin:10px 0 10px 0;font-family: 'DingTalk-JinBuTi';">{{pointAmount}}</div>
					 	<div style="font-size: 16px;color: #8794A7;" class="margin-b-20">积分</div>
					 </div>
				</div>
				<div style="display: flex;justify-content: space-between;margin-top: 25px;">
					<div class="width20 text-left font-size14">	
						<div class="width20-item pointer flex-column-center-center" @click="CouPon">
							<img src="@/assets/imgs/youhuiquan.png" width="30px" height="30px" />
							<div class="width20-font-blod font-size30" style="font-family: 'DingTalk-JinBuTi';">
								{{customerMoreInfo.fCouponCount}}
							</div>
							<div style="font-size: 16px;color: #8794A7;">优惠券</div>
						</div>
					</div>
					<div class="width20 text-left font-size14" @click="myinvite()">
						<div class="width20-item pointer flex-column-center-center">
							<img src="@/assets/imgs/yaoqing.png" width="30px" height="30px" />
							<div class="width20-font-blod font-size30" style="font-family: 'DingTalk-JinBuTi';">
								{{fInvitationNum}}
							</div>
							<div style="font-size: 16px;color: #8794A7;">邀请</div>
						</div>
					</div>
					<div class="width20 text-left font-size14">
						<div class="width20-item pointer flex-column-center-center" @click="MyCollect">
							<img src="@/assets/imgs/shoucang.png" width="30px" height="30px" />
							<div class="width20-font-blod font-size30" style="font-family: 'DingTalk-JinBuTi';">{{customerMoreInfo.fCollectionNum}}</div>
							<div style="font-size: 16px;color: #8794A7;">收藏</div>
						</div>
					</div>
					<div class="width20 text-left font-size14">
						<div class="width20-item pointer flex-column-center-center" @click="MyFocus">
							<img src="@/assets/imgs/guanzhu.png" width="30px" height="30px" />
							<div class="width20-font-blod font-size30" style="font-family: 'DingTalk-JinBuTi';">
								{{customerMoreInfo.focusNum}}
							</div>
							<div style="font-size: 16px;color: #8794A7;">关注</div>
						</div>
					</div>
					<div class="width20 text-left font-size14">
						<div class="width20-item pointer flex-column-center-center" @click="goldCoinLotteryTicket()">
							<img src="@/assets/imgs/jinbiquan.png" width="30px" height="30px" />
							<div class="width20-font-blod  font-size30" style="font-family: 'DingTalk-JinBuTi';">
								{{customerCount}}
							</div>
							<div style="font-size: 16px;color: #8794A7;">抽奖券</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="carouselBox" v-if="list.length > 0">
			<el-carousel :autoplay="true" :interval="interval" height="110px">
				<el-carousel-item class="width-fill" v-for="(item1, index) in list" :key="index" >
					<img :src="item1.fPath" class="wh-100"  @click="bannerclicks(item1)"/>
					<!-- 				<div class="flex-row" >
						<div class="img-wh" v-for="(item1,index) in list" :key="index">
							<img :src="item1.fPath" class="width-fill" />
						</div>
						<div class="img-wh padding-l-20" v-for="(item2,index) in list2" :key="1+index">
							<img :src="item2.fPath" class="width-fill" />
						</div>
					</div> -->
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>
<script>
	import getUploadFileURL from "@/utils/uploadFile.js";
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				fit: "cover",
				isUnderReview: false, //是否审核中
				url: require("@/assets/personal.png"),
				interval: 2000,
				list: [
					// {
					// 	fPath: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
					// },
					// {
					// 	fPath: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
					// },
				],
				//list2:[
				// {
				// 	fPath: "https://img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
				// }
				//]
				customerMoreInfo: {
					fCollectionNum: 0, //收藏
					fCouponCount: 0, //优惠券
					fEndBalance: 0, //金币
					fGrowupNum: 0, //成长值 会员值
					fInvitationNum: 0, //邀请数
					focusNum: 0, //关注
				},
				customerCount: 0, // 抽奖券
				fInvitationNum: 0,
				userInfo: {
					fUserName: '',
					fVerifiedStatus: false,
					gradeName: '普通会员',
					phone: '',
					govName: '',
					customerName: '',
					loginName: '',
					cardId: '',
					gradeId: '',
					fCommerceTypeID: '',
				},
				pointAmount:0,
				ShowPerfect: false,
			};
		},
		computed: {
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getUserInfoData();
			this.carouselImgList();
			this.getInviteStatistics();
		},
		methods: {
			gomyPoints(){
				this.$router.push({
					path: "/PersonalContent/MyPoints",
				});
			},
			gomemberCenter(){
				this.$router.push({
					path: "/memberCenter",
				});
			},
			// 轮播图点击事件
			bannerclicks(e){
				console.log("66666666666666666666666666666666666",e)
				if(e.fTargetTypeID == "1" && e.fTargetUrl.indexOf("OpenShop") != -1){
					var isThereAStore;
					this.ApiRequestPost('api/mall/ebshop/create-requestbill/get-shop-application', {})
						.then(
							res => {
								console.log('获取当前登录人有没有店铺', res);
								if (res.obj) {
									if (res.obj.fApproveStatus == 0) {
										isThereAStore = false;
										this.$router.push({
											path: e.fTargetUrl
										});
									} else {
										console.log("开店信息",res)
										isThereAStore = true;
										if(res.obj.fShopTypeID == "2"){
											// 工厂店
											this.$router.push('/OpenShop/OpenShopForm')
										}
										if(res.obj.fShopTypeID == "3"){
											// 经销商
											this.$router.push('/OpenShop/OpenDistributorShopForm')
										}
									}
								} else {
									isThereAStore = false;
									if(this.getUserInfo.fCommerceTypeID != '2'){
										this.$message('个人用户不能开店');
										return;
									}else{
										this.$router.push({
											path: e.fTargetUrl
										});
									}
								}
							},
							error => {}
						);
				}else if(e.fTargetTypeID == "1" && e.fTargetUrl.indexOf("CarrierRegistration") != -1){
					console.log("走的else")
					if(this.getUserInfo.fCommerceTypeID != '2'){
						this.$message('个人用户不能成为承运商');
						return;
					}
					this.$router.push({
						path: e.fTargetUrl
					});
				}else{
					
				}
			},
			//获取用户基本资料
			getUserInfoData() {
				if (JSON.stringify(this.getUserInfo) != '{}') {
					this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-current-customer-user-info')
						.then(
							res => {
								console.log(res);
								this.userInfo = res.obj;
								if (!this.userInfo.fVerifiedStatus) {
									this.ShowPerfect = true;
								}
								if (this.userInfo.fFileName) {
									this.url = this.userInfo.fFileName;
								}
								if (this.userInfo.fFileName != this.getUserInfo.fFileName) {
									this.$store.commit("setUserInfo", res.obj);
									sessionStorage.setItem("setUserInfo", JSON.stringify(res.obj));
								}
								this.getUserCurrentBill();
								this.getCustomerMoreInfo();
								this.getCustomerCount();
								this.getCustomerMorepoint();
							},
							error => {}
						);
				}
			},
			//获取用户所有积分
			getCustomerMorepoint() {
					  this.ApiRequestPostNOMess('api/mall/ebbalance/customer-point/get-all-point-amount')
					    .then(
					      res => {
					        console.log('res积分',res);
					        this.pointAmount = Number(res.obj.sumCustomerPoint)
					      },
					      error => {}
					    );
			},
			//获取用户完善资料
			getUserCurrentBill() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
					.then(
						res => {
							console.log(res);
							if (res.obj) {
								if (res.obj.fApproveStatus == 1) { //1审核中 254审核成功
									this.isUnderReview = true;
								}
							}
						},
						error => {}
					);
			},
			//获取优惠券关注等信息
			getCustomerMoreInfo() {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/baseinfo/get-customer-more-info')
					.then(
						res => {
							console.log(res);
							this.customerMoreInfo = res.obj
						},
						error => {}
					);
			},
			//获取抽奖券数量
			getCustomerCount() {
				this.ApiRequestPostNOMess('/api/mall/ebactivity/gift-record/get-customer-count')
					.then(
						res => {
							console.log(res);
							this.customerCount = res.obj
						},
						error => {}
					);
			},
			//获取邀请数量
			getInviteStatistics() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-invite-statistics')
					.then(
						res => {
							console.log(res);
							this.fInvitationNum = res.obj.number
						},
						error => {}
					);
			},
			/* 获取轮播图数据*/
			carouselImgList() {
				this.ApiRequestPost(
					"api/mall/ebbase/ebadvertisement/get-person-center-ad-list", {
						fAppTypeID: this.fAppTypeID
					}
				).then(
					(result) => {
						this.list = result.obj;
						// imgList.forEach((item,index) => {
						// item.fPath = this.getUploadFileURL(item.fPath)
						// if (index/2 === 0) {
						//  this.list = imgList
						// }else{
						//    this.list2 = imgList;
						// }
						// })
						// this.list = result.obj;
						// for (let item of this.list) {
						// 	item.fPath = this.getUploadFileURL(item.fPath)
						// }
					},
					(error) => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			//邀请
			myinvite() {
				this.$router.push("/PersonalContent/myinvite");
			},
			//优惠券
			CouPon() {
				this.$router.push({
					path: "/PersonalContent/CouPon",
					query: "",
				});
			},
			//金币奖券
			goldCoinLotteryTicket() {
				this.$router.push({
					path: "/PersonalContent/goldCoinLotteryTicket",
					query: "",
				});
			},
			
			//登录
			toLogin() {
				this.$router.push("/login");
			},
			//去完善
			Allperfect() {
				if (this.isUnderReview) {
					this.goToUnderReview()
				} else {
					this.$router.push("/PersonalContent/Allperfect");
				}

			},
			//跳转审核中页面
			goToUnderReview() {
				console.log('跳转审核中');
				// this.$router.push("/about/Allperfect");
				this.$router.push({
					path: "/UnderReview",
				});
			},
			//去金币商城
			toGoldMall() {
				this.$router.push("/goldMall");
			},
			//
			News() {
				this.$router.push({
					path: "/News",
					query: "",
				});
			},
			//关注
			MyFocus() {
				this.$router.push({
					path: "/PersonalContent/MyFocus",
					query: "",
				});
			},
			//收藏
			MyCollect() {
				this.$router.push({
					path: "/PersonalContent/MyCollect",
					query: "",
				});
			},
		},
	};
</script>
<style scoped lang="scss">
	.aaa {
		margin-top: 10px;
	}

	.padding-l-20 {
		padding-left: 20px;
	}

	.margin0-auto {
		margin: 0 auto;
	}

	.width20-item {
		width: 114px;
		margin: 0 auto;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.top-suff {
		width: 100%;
		height: 333px;
		background-color: #ffffff;
		border-radius: 10px;
	}

	.suff-left {
		width: 344px;
		height: 333px;
		background: #c6ddff;
		border-radius: 10px 0 0 10px;
	}

	.suff-row {
		width: 577px;
		background-color: #FFF;
		box-shadow: 0px 0px 10px 0px rgba(204,204,204,0.35);
		border-radius: 0 10px 10px 0;
	}

	.jucai-pinyin {
		padding-top: 18px;
		// line-height: 51px;
		opacity: 0.2;
		font-size: 40px;
		text-align: center;
	}

	.user {
		display: block;
		width: 80px;
		height: 80px;
		border: 4px solid #ffffff;
		border-radius: 50%;
		overflow: hidden;
		margin: -10px auto 0 auto;
		box-shadow: 0px 0px 10px 0px #0033cc; 
	}

	.grade {
		width: 917px;
		height: 18px;
		position: absolute;
		display: flex;
		justify-content: center;
	}

	.u_my_vip {
		padding: 0 2px;
		height: 18px;
		background: #ff6600;
		border: 0px solid rgba(255, 255, 255, 0);
		font-weight: 400;
		position: absolute;
		top: -10px;
		border-radius: 3px;
		// left: 116px;
	}

	.useName {
		margin-top: 17px;
		width: 100%;
		text-align: center;
		// position: absolute;
		// top: 125px;
		// left: 100px;
	}

	.customer {
		width: 263px;
		text-align: center;
		line-height: 13px;
	}

	.center-left {
		width: 240px;
		height: 135px;
		position: relative;
		background: #232331;
	}

	.padding-t-b-r10 {
		padding: 15px 20px;
	}

	.center-right {
		width: 636px;
		height: 135px;
		background: #f8f9fe;
		border-radius: 0 60px 60px 0;
		box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.349019607843137);
	}

	.jinfen-bgc {
		width: 100%;
		height: 60px;
		position: absolute;
		top: 75px;
		left: 0;
		background: url(../../assets/imgs/jifen-bgc.png) no-repeat 0 0;
		background-size: 100% 100%;
	}

	.memberNum {
		width: 90px;
		line-height: 25px;
		border-radius: 30px;
		color: #f4ca3a;
		border: 1px solid #f4ca3a;
		position: absolute;
		top: 35px;
		left: 70px;
	}

	.bold-memberbtn {
		width: 90px;
		line-height: 28px;
		background: #f4ca3a;
		border-radius: 30px;
		color: #232331;
		position: absolute;
		left: 70px;
		top: 71px;
		z-index: 6;
	}

	.p-content {
		width: 80%;
		margin: 0 auto;
	}

	.width20 {
		width: 20%;

		.width20-font-blod {
			// margin: 45px auto 10px auto;
			font-weight: 400;
			line-height: 50px;
		}
	}

	.message-box {
		width: 917px;
		height: 50px;
		background: #FFE6E6;
		// border-radius: 10px;

		.message-box-i {
			margin: 10px;
			color: #FF3300;
		}
	}

	.attestat_box {
		width: 100%;
		line-height: 35px;
		color: #ff3300;
		text-align: left;
	}

	.perfert_btn {
		margin-right: 20px;
		width: 142px;
		line-height: 32px;
		height: 32px;
		background-color: #D20005;
		color: #FFFFFF;
		border-radius: 30px;
	}

	// .applyShop,
	// .recommenduser {
	// 	margin-top: 15px;
	// 	width: 452px;
	// 	height: 135px;
	// 	box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.349019607843137);
	// }

	// .applyShop {
	// 	margin-left: 13px;
	// 	background: #cdecfe;
	// }

	// .recommenduser {
	// 	background: #ffdedc;
	// }

	.el-divider--horizontal {
		margin: 0;
	}

	.z-inde-9999 {
		z-index: 9999;
	}

	::v-deep .el-divider {
		background-color: #f2f2f2;
	}

	.carouselBox::v-deep {
		width: 100%;
		height: 120px;
		padding: 10px 10px 0 0;

		.el-carousel .el-carousel__indicators {
			display: none;
		}
	}

	.width-fill {
		width: 100%;
	}

	.renzheng_btn,
	.no_zr_btn,
	.rz_ing_btn {
		width: 40px;
		height: 15px;
		border-radius: 2px;
	}

	.renzheng_btn {
		background: #3366FF;
		color:#ffffff;
		padding:2px 5px;
	}

	.no_zr_btn {
		background: #ff0000;
	}

	.rz_ing_btn {
		background: #00cc00;
	}

	// .img-wh {
	// 	width: calc((100% - 20px)/2);
	// 	// width: 40%;
	// 	height: 100%;
	// }

	.wh-100 {
		width: 100%;
		// height: 100%;
	}
	.leftBottom{
		width:344px;
		height:85px;
		background-image: url('../../assets/imgs/leftBottom.png');
		background-repeat: repeat;
		margin-top: 35px;
	}
	.iconYou{
		background: linear-gradient(to right,#FFFFFF, #F1C685);
		background-clip: text;
		-webkit-background-clip: text;
		 -webkit-text-fill-color: transparent;
		font-size:13px
	}
	@font-face {
		font-family: 'DingTalk-JinBuTi';
		src: url("../../assets/DingTalk-JinBuTi.ttf")
	}
</style>
