<template>
	<el-breadcrumb separator-class="el-icon-arrow-right">
		<el-breadcrumb-item v-for="(item,index) in breadList" :key="index" :to="{ path: item.path }">
			{{item.meta.title}}
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>
<script>
	export default {
		data(){
			return{
				breadList:[]
			}
		},
		mounted() {
			this.getBreadcrumb()
		},

		methods: {
			isHome(route) {
				return route.name === "Home";
			},
			getBreadcrumb() {
				
				let matched = this.$route.matched;
				
				let data = []
				if (matched[2].name == 'Evaluate' || matched[2].name == 'RefundApplication') {
					if (this.$route.query.detailsname == '全部订单') {
						sessionStorage.ApplicationName = this.$route.query.detailsname
					} else {
						sessionStorage.ApplicationName = this.$route.query.detailsname.slice(0, -2)
					}
					let obj = {
						path: this.$route.query.detailspath,
						meta: {
							title: this.$route.query.detailsname
						}
					}
					data.push(obj)
					let obj2 = {
						path: this.$route.path,
						meta: {
							title: this.$route.meta.title
						}
					}
					data.push(obj2)
					
				} else {
					for (let item of matched) {
						if (item.name != 'RouteBox') {
							data.push(item)
						}
					}
				}
				
				// matched = data;
			
				if (!this.isHome(data[0])) {
					data = [{
						path: "/",
						meta: {
							title: "首页"
						}
					}].concat(data);
				}
				this.breadList = data;
			
			}
		},
		watch: {
			$route(e) {
				
				this.getBreadcrumb();
				
			}
		},
	}
</script>
<style scoped="scoped">
	.el-breadcrumb {
		line-height: normal;
	/* 	background: #f8f9fe; */
	}
	.el-icon-arrow-right{
		
		}
</style>
