import RouteBox from '@/components/RouteBox'
import Index from '@/views/About'

export default {
	path: '/PersonalContent',
	name: 'PersonalContent',
	component: Index,
	meta: {
		title: "个人中心"
	},
	redirect: "/PersonalContent",
	children: [
		{
			path: '/',			
			component: () => import('@/views/myCenter/PersonalContent.vue'),
		
		},
		{
			path: 'myPoints',
			name: 'myPoints',
			component: () =>import('@/views/myCenter/my/MyPoints.vue'),
			meta: {
				title: "我的积分",
			},
		
		},
		{
			path: 'QuestionnaireInvestigation',
			name: 'QuestionnaireInvestigation',
			component: () =>import('@/views/QuestionnaireInvestigation.vue'),
			meta: {
				title: "问卷调查",
			},
		
		},
		
		 {
			path: 'myAccount',
			name: 'myAccount',
			component: () => import('@/views/myCenter/MyAccount/MyAccount.vue'),
			meta: {
				title: "我的账户",
			},
		
		},
		{
			path: 'myAddress',
			name: 'myAddress',
		
			component: () => import('@/views/myCenter/informationMaintenance/MyAddress.vue'),
			meta: {
				title: "我的地址",
			},
			
		},
		{
			path: 'newAddress',
			name: 'NewAddress',
	
			component: () => import('@/views/myCenter/informationMaintenance/NewAddress.vue'),
			meta: {
				title: "新增地址",
			},
		
		},
		{
			path: 'myVehicle',
			name: 'myVehicle',
			
			component: () => import('@/views/myCenter/informationMaintenance/MyVehicle.vue'),
			meta: {
				title: "我的车辆",
			},
			
		},
		{
			path: 'newVehicle',
			name: 'newVehicle',
			component: () => import('@/views/myCenter/informationMaintenance/NewVehicle.vue'),
			meta: {
				title: "新增车辆",
			},
			
		},
		{
			path: 'NewGroup',
			name: 'NewGroup',
			component: () => import('@/views/myCenter/informationMaintenance/NewGroup.vue'),
			meta: {
				title: "新增车辆分组",
			},
			
		},
		{
			path: 'BillWallet',
			name: 'BillWallet',
			
			component: () => import('@/views/myCenter/wallet/BillWallet.vue'),
			meta: {
				title: "票据钱包",
			},
		
		},
		{
			path: 'MoneyDash',
			name: 'MoneyDash',
			component: RouteBox,
			meta: {
				title: "现金钱包",
			},
			children: [{
				path: '/',
				component: () => import('@/views/myCenter/MoneyDash.vue'),
			}]
		},
		{
			path: 'myBankCard',
			name: 'MyBankCard',
			
			component: () => import('@/views/myCenter/wallet/MyBankCard.vue'),
			meta: {
				title: "我的银行卡",
			},
			
		},
		{
			path: 'newBankCard',
			name: 'NewBankCard',
			component: RouteBox,
			meta: {
				title: "新增银行卡",
			},
			children: [{
				path: '/',
				component: () => import('@/views/myCenter/wallet/NewBankCard.vue'),
			}]
		},
		{
			path: 'AccountPay',
			name: 'AccountPay',
			component: RouteBox,
			meta: {
				title: "账服通钱包",
			},
			children: [{
				path: '/',
				component: () => import('@/views/myCenter/AccountPay.vue'),
			}]
		},
		{
			path: 'HelpCenter',
			name: 'HelpCenter',
			component: () => import('@/views/myCenter/HelpCenter.vue'),
			meta: {
				title: "帮助中心",
			}
		},
		{
			path: 'MyPerson',
			name: 'MyPerson',
			component: () => import('@/views/myCenter/MyPerson.vue'),
			meta: {
				title: "我的员工",
			},
			
		},
		{
			path: 'newPersonbtn',
			name: 'newPersonbtn',
			component: RouteBox,
			meta: {
				title: "新增员工",
			},
			children: [{
				path: '/',
				component: () => import('@/views/myCenter/newPersonbtn.vue'),
			}]
		},
		{
			path: 'ManagementPosition',
			name: 'ManagementPosition',
			component: RouteBox,
			meta: {
				title: "管理岗位",
			},
			children: [{
				path: '/',
				component: () => import('@/views/myCenter/informationMaintenance/ManagementPosition.vue'),
			}]
		},
		{
			path: 'EditManagementPosition',
			name: 'EditManagementPosition',
			component: RouteBox,
			meta: {
				title: "新增岗位",
			},
			children: [{
				path: '/',
				component: () => import('@/views/myCenter/informationMaintenance/EditManagementPosition.vue'),
			}]
		},
		{
			path: 'myinvite',
			name: 'myInvite',
			component: () => import('@/views/myCenter/AccountSet/myInvite.vue'),
			meta: {
				title: "我的邀请",
			}
		},
    {
			path: 'moremyinvite',
			name: 'moremyinvite',
			component: () => import('@/views/myCenter/AccountSet/moremyinvite.vue'),
			meta: {
				title: "我的邀请",
			}
		},
		{
			path: 'AllPerfect',
			name: 'AllPerfect',
			component: () => import('@/views/myCenter/AllPerfect.vue'),
			meta: {
				title: "完善资料",
			}
		},
		{
			path: 'Improve',
			name: 'Improve',
			component: () => import('@/views/myCenter/Improve.vue'),
			meta: {
				title: "完善信息",
			}
		},
		{
			path: 'Enterprise',
			name: 'Enterprise',
			component: () => import('@/views/myCenter/Enterprise.vue'),
			meta: {
				title: "企业资料",
			}
		},
		{
			path: 'PersonalProfile',
			name: 'PersonalProfile',
			component: () => import('@/views/myCenter/PersonalProfile.vue'),
			meta: {
				title: "个人资料",
			}
		},
		{
			path: 'CouPon',
			name: 'CouPon',
			component: () => import('@/views/myCenter/CouPon.vue'),
			meta: {
				title: "我的优惠券",
			}
		},
		{
			path: 'goldCoinLotteryTicket',
			name: 'goldCoinLotteryTicket',
			component: () => import('@/views/myCenter/goldCoinLotteryTicket.vue'),
			meta: {
				title: "我的抽奖券",
			}
		},
		{
			path: 'myFocus',
			name: 'MyFocus',
			component: () => import('@/views/myCenter/MyFocus.vue'),
			meta: {
				title: "我的关注",
			}
		},
		{
			path: 'myStuff',
			name: 'myStuff',
			component: () => import('@/views/myCenter/AccountSet/myStuff.vue'),
			meta: {
				title: "我的资料",
			}
		},
		{
			path: 'loginPassword',
			name: 'LoginPassword',
			component: () => import('@/views/myCenter/AccountSet/LoginPassword.vue'),
			meta: {
				title: "登录密码",
			}
		},
		{
			path: 'loginSetPass',
			name: 'LoginSetPass',
			component: () => import('@/views/myCenter/AccountSet/LoginSetPass.vue'),
			meta: {
				title: "登录设置密码",
			}
		},
		{
			path: 'ChangePassword',
			name: 'ChangePassword',
			component: () => import('@/views/myCenter/AccountSet/ChangePassword.vue'),
			meta: {
				title: "修改密码",
			}
		},
		{
			path: 'accountConnection',
			name: 'AccountConnection',
			component: () => import('@/views/myCenter/AccountSet/AccountConnection.vue'),
			meta: {
				title: "账号关联",
			}
		},
		{
			path: 'paymentPassword',
			name: 'PaymentPassword',
			component: () => import('@/views/myCenter/AccountSet/PaymentPassword.vue'),
			meta: {
				title: "设置支付密码",
			}
		},
		{
			path: 'cancelAccount',
			name: 'CancelAccount',
			component: () => import('@/views/myCenter/AccountSet/CancelAccount.vue'),
			meta: {
				title: "注销账户",
			}
		},
		{
			path: 'InvoiceInformation',
			name: 'InvoiceInformation',
			component: () => import('@/views/myCenter/informationMaintenance/InvoiceInformation.vue'),
			meta: {
				title: "我的发票",
			}
		},
		{
			path: 'MyCollect',
			name: 'MyCollect',
			component: () => import('@/views/myCenter/AccountSet/MyCollect.vue'),
			meta: {
				title: "我的收藏",
			}
		},
		{
			path: 'EditInvoice',
			name: 'EditInvoice',
			component: () => import('@/views/myCenter/informationMaintenance/EditInvoice.vue'),
			meta: {
				title: "编辑发票",
			}
		},
		{
			path: 'NewInvoice',
			name: 'NewInvoice',
			component: () => import('@/views/myCenter/informationMaintenance/NewInvoice.vue'),
			meta: {
				title: "新增发票",
			}
		},
		{
			path: 'ChangeInvoice',
			name: 'ChangeInvoice',
			component: () => import('@/views/myCenter/informationMaintenance/ChangeInvoice.vue'),
			meta: {
				title: "变更发票",
			}
		},
		{
			path: 'AddInvoice',
			name: 'AddInvoice',
			component: () => import('@/views/myCenter/informationMaintenance/AddInvoice.vue'),
			meta: {
				title: "收件信息",
			}
		},
		{
			path: 'pointsBill',
			name: 'pointsBill',
			component: () => import('@/views/myCenter/my/pointsBill.vue'),
			meta: {
				title: "积分账单明细",
			}
		},
		
	]
}
