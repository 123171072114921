export default {
	state: {
		// 主题名称
		themeName: 'themes_blue',
		themeColor: '#0173FE',
		// 公共传参
		commonData: null,
		logoUrl: ''
	},
	getters: {
		// 获取主题
		getThemeName: state => {
			return state.themeName
		},
		// 获取主题
		getThemeColor: state => {
			return state.themeColor
		},
		// 获取公共参数
		getCommonData: state => {
			return state.commonData
		},
		//获取LOGO URL
		getLogoUrl: state => {
			return state.logoUrl
		}
	},
	mutations: {
		// 变更主题
		changeThemeName(state, theme) {
			state.themeName = theme;
		},
		// 变更公共参数
		changeCommonData(state, value) {
			state.commonData = value;
		},
		//变更LOGO URL
		setLogoUrl(state, value) {
			state.logoUrl = value;
		}
	},
	actions: {
		changeCommonData({
			commit
		}, value) {
			commit('changeCommonData', value);
		},
		setLogoUrl({
			commit
		}, value) {
			commit('setLogoUrl', value);
		}

	}
}
