<template>
	<!-- 用户认证提示页 -->
	<div id="attestation">
	<el-dialog title="登录" width="346px" center :visible.sync="isAttestation" :close-on-click-modal="false"
	 :close-on-press-escape="false">
		<span class="el-icon-circle-close closeicon" @click="closeMo"></span>
		<div style="text-align: center;">
				<img :src="img1" style="width: 240px;margin-top: -83px;height:238px" />
				<p class="p1">
					请认证您的真实身份信息
				</p>
				<p class="p2">
					为了您能更方便的使用我们的产品
				</p>
				<p class="p2" style="margin-bottom:15px;">
					请您先进行实名认证哦~
				</p>
				<div class="btn pointer" @click="jumpPage">
					立即去认证
				</div>
				<p class="p2 pointer" @click="closeMo">
					暂不认证
				</p>
		</div>
	</el-dialog>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	export default {
		name: "Attestation",
		data() {
			return {
				img1: require("@/assets/u6.gif"),
			}
		},
	computed: {
		// 获取vuex中的showLogin，控制登录组件是否显示
		isAttestation: {
			get() {
				return this.$store.getters.getShowAttestation;
			},
			set(val) {
				this.setShowAttestation(val);
			}
		}
	},
	methods: {
		...mapActions(["setUser", "setShowAttestation"]),
		closeMo(){
				this.isAttestation = false;
		},
		jumpPage() {
			this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
			.then(
				res => {
					console.log('用户完善资料', res);
					if (res.obj) {
						if (res.obj.fApproveStatus == 1) { //1审核中 254审核成功
							this.$router.push({
								path: "/UnderReview",
							});
						} else {
							this.$router.push("/PersonalContent/Allperfect")
						}
						this.closeMo();
					} else {
						this.$router.push("/PersonalContent/Allperfect")
						this.closeMo();
					}
				},
				error => {
					this.$router.push("/PersonalContent/Allperfect")
					this.closeMo();
				}
			);
		}
	}}
</script>

<style>
	#attestation .el-dialog--center .el-dialog__body {
		padding:12px 20px;
	}
	
	#attestation .el-dialog__body {
		padding: 0;
	}
	#attestation .el-dialog__header {
		display: none;
	}
	#attestation  .btn{
			border-width: 0px;
		    background: url(../../assets/u4.png) no-repeat center;
			background-size:100%;
		    left: 139px;
		    top: 455px;
		 
		    height: 45px;
			line-height: 45px;
		    font-size: 16px;
		    color: #FFFFFF;
			margin-bottom: 15px;
	}
	#attestation .p1{
		color:#333;
		font-size: 17px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	#attestation .p2{
		color:#999999;
		font-size:14px;
		    margin-bottom: 2px;
		
	}
	#attestation .closeicon {
		color: #fff;
		font-size: 32px;
		top: -33px;
		position: absolute;
		right: -46px;
	}
	
</style>

