<template>
	<div class="help-center">
		<leftMenus></leftMenus>
		<HelpCenter></HelpCenter>			
	</div>
</template>

<script>
	import LeftMenus from '@/views/HelpCenter/LeftMenu'
	import HelpCenter from '@/views/HelpCenter/HelpCenter.vue'
	export default {
		components: {
			LeftMenus,HelpCenter
		},
	}
</script>

<style scoped>
	.help-center {
		width: 1110px;
		margin: 0 auto;
		display: flex;
	}
</style>
