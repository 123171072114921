<!-- 
使用方法
 引入：
 1	import agreementDialog from '@/components/agreementDialog';
 components注册 agreementDialog
<agreementDialog  agreTitle="企业购协议" :iContent="iContenthtml" ref="areDialog"/>
this.$refs.areDialog.show();	

-->
<!-- 协议弹框 -->
<template>
	<el-dialog :visible.sync="noticeShow" width="50%" height="50%" @close="closeNoticeShow" append-to-body
		:close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialogNotice">
		<div style="position: fixed;top: 15%;background-color: #FFFFFF;width: 47%;">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane v-for="(item,index) of list" :label="item.fTitle" :name="item.fAgreementHistID">
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="dialogScroll font-size12">
			<!-- <div class="font-size20 margin-b-15" style="text-align: center;">{{titles}}</div> -->
			<RichText :html="iContent+``"
				v-watermark="{text:'新疆聚材电子商务有限公司',font:'20px Alibaba_PuHuiTi_Regular',width:'100%',textColor:'#c4c4c4'}" />
		</div>
		<div class="tcpButton">
			<el-button class="downclass"   type="primary" style="background-color: #0173fe;" @click="downloadDeal"
				>下载此协议
			</el-button>
			<el-button class='closebtn'  style="border: 1px solid #0173fe;"  @click="closeNoticeShow">关闭
			</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import RichText from "@/components/richtext/RichText";
	import watermark from "@/utils/watermark.js";
	export default {
		props: {
			iContents: {
				type: String,
				default: '',
			},
			list: {
				type: Array,
				default: function() {
					return [];
				}
			},
			configshow: {
				type: Boolean,
				default: false
			},
			type: {
				type: String,
				default: 'default'
			}
		},
		watch: {

			// 根据协议ID获取协议内容 ，如果协议接口不改的话，那么这个组件可以复用到任何地方
			// 
			iContents: {
				deep: true,
				handler(val, oldVal) {
					this.activeName = val;
					if (this.type == 'default') {
						this.getContent(val);
					} else {
						this.getContent1(val);
					}
				}
			}
		},

		components: {
			RichText,

		},
		data() {
			return {
				activeName: '',
				noticeShow: false,
				iContent: '',
				titles: "",
				dealID: "",
				iContentsId:''//协议类型id
			}

		},
		mounted() {
			// watermark.set("仅用于专家评审")
		},
		methods: {
			handleClick(tab) {
				this.$emit('handleClick', tab.name)
			},
			getContent(val) {
				this.ApiRequestPostNOMess(
					"api/mall/ebbase/agreement-history/get", {
						FAgreementHistID: val
					}
				).then(
					(result) => {
						this.dealID = result.obj.fAgreementHistID
						this.titles = result.obj.fTitle
						this.iContent = result.obj.fContent
						this.iContentsId = result.obj.fAgreementTypeID
					},
					(rej) => {}
				);
			},
			getContent1(val) {
				this.ApiRequestPostNOMess(
					"api/mall/ebbase/agreement-history/get-by-agreement-tyoeid", {
						id: val
					}
				).then(
					(result) => {
						this.dealID = result.obj.fAgreementHistID
						this.titles = result.obj.fTitle
						this.iContent = result.obj.fContent
						this.iContentsId = result.obj.fAgreementTypeID
					},
					(rej) => {}
				);
			},
			closeNoticeShow() {
				this.$emit('closeNoticeShow', true)
				this.noticeShow = false;
			},
			show() {
				this.noticeShow = true;
			},
			// 下载相关协议
			downloadDeal() {
				this.ApiRequestPostNOMess(
					"api/mall/ebbase/agreement-history/down-agreement-by-id", {
						id: this.iContentsId
					}
				).then(
					(result) => {
						window.open(result.obj);
					},
					(rej) => {}
				);

			}

		},
		created() {},
	}
</script>

<style lang="scss" scoped>
	.close-notice-btn {
		position: fixed;
		right: 96%;
		top: -6%;
		z-index: 102;
	}


	::v-deep .dialogNotice .el-dialog__headerbtn {
		position: absolute;
		// top: -100%;
		// right: -6%;
		cursor: pointer;
		font-size: 16px;
		// border-radius: 50%;
		width: 30px;
		height: 30px;
		color: #ffffff;
		// border: 2px solid #ffffff;
	}

	::v-deep .el-dialog {
		overflow-x: hidden;
		overflow-y: auto;
		min-height: 40vh;
		max-height: 70vh;
		margin-top: 15vh;

	}

	::v-deep .dialogNotice .el-dialog__headerbtn .el-dialog__close {
		color: #000;
	}

	::v-deep .el-dialog__header {
		padding: 10px 20px 10px;
		text-align: center;
		//width: 47%;
		width: calc(50% - 17px);
		width: -webkit-calc(50% - 17px);
		width: -moz-calc(50% - 17px);
		text-align: center;
		background-color: #fff;
		position: fixed;
		box-sizing: border-box;
	}

	::v-deep .el-dialog__body {
		padding: 0px 20px 20px 20px;
		height: 100%;
	}

	.dialogScroll {
		max-height: 500px;
		overflow-y: scroll;
		padding-top: 50px
	}

	::v-deep .el-tabs__header {
		margin: 0;
	}

	.tcpButton {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 50px;
		margin : auto;
		margin-top: 10px;
	}
	.closebtn{
		width: 95px;
		height: 36px;
		border-radius: 3px;
		font-weight: 400;
		color: #0173fe;
		line-height: 0.5;
	}
	.downclass{
		width: 119px;
		height: 36px;
		background: #0173fe;
		border-radius: 4px;
		font-weight: 400;
		line-height: 0.5;
	}
</style>
