import RouteBox from '@/components/RouteBox'
import Index from '@/views/About'

export default {
	path: '/SalesNetwork',
	name: 'SalesNetwork',
	component: RouteBox,
	meta: {
		title: "销售网络",
	},
	children: [{
		path: '/',
		component: () => import('@/views/SalesNetwork.vue'),
	}],
}
