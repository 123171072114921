import RouteBox from '@/components/RouteBox'
import Index from '@/views/About'

export default {
	path: '/Goods',
	name: 'Goods',
	component: RouteBox,
	meta: {
		title: "商品列表",
	},
	children: [{
			path: '/',
			component: () => import('@/views/HomePage/Goods/Goods.vue'),
		    meta:{
				keepAlive:true
			}
		},
		{
			path: 'GoodsDetails',
			name: 'GoodsDetails',
			component: () => import('@/views/HomePage/Goods/GoodsDetails.vue'),
			meta: {
				title: "商品详情",
			},
		},
		{
			path: 'IntegralGoodsDetails',
			name: 'IntegralGoodsDetails',
			component: () => import('@/views/HomePage/IntegralGoods/IntegralGoodsDetails.vue'),
			meta: {
				title: "商品详情",
			},
		},
		{
			path: 'SeckkillGoodsDetails',
			name: 'SeckkillGoodsDetails',
			component: () => import('@/views/HomePage/IntegralGoods/SeckkillGoodsDetails.vue'),
			meta: {
				title: "商品详情",
			},
		},
		{
			path: 'PointBuyGoodsBillDetails',
			name: 'PointBuyGoodsBillDetails',
			component: () => import('@/views/HomePage/PointBuyGoodsBillDetails/PointBuyGoodsBillDetails.vue'),
			meta: {
				title: "商品详情",
			},
		},
		{
			path: 'FillOrderPointBuyGoodsBill',
			name: 'FillOrderPointBuyGoodsBill',
			component: () => import('@/views/HomePage/PointBuyGoodsBillDetails/FillOrderPointBuyGoodsBill.vue'),
			meta: {
				title: "填写订单",
			},
		},
		{
			path: 'FillOrderIntegral',
			name: 'FillOrderIntegral',
			component: () => import('@/views/HomePage/IntegralGoods/FillOrderIntegral.vue'),
			meta: {
				title: "填写订单",
			},
		},
		{
			path: 'GoodsDetailsB2B',
			name: 'GoodsDetailsB2B',
			component: () => import('@/views/HomePage/Goods/GoodsDetailsB2B.vue'),
			meta: {
				title: "商品详情", //企业购商品详情
			},
		},
		{
			path: 'Evaluate',
			name: 'Evaluate',
			component: () => import('@/views/business/Order/Evaluate.vue'),
			meta: {
				title: "评价",
			},
		},
		{
			path: 'RefundApplication',
			name: 'RefundApplication',
			component: () => import('@/views/business/Order/RefundApplication.vue'),
			meta: {
				title: "申请退款",
			},
		},
    {
			path: 'AllRefundApplication',
			name: 'AllRefundApplication',
			component: () => import('@/views/business/Order/AllRefundApplication.vue'),
			meta: {
				title: "申请批量退款",
			},
		},
		{
			path: 'ShopList',
			name: 'ShopList',
			component: () => import('@/views/HomePage/Goods/ShopList.vue'),
			meta: {
				title: "店铺列表",
			},
		},
	],
}
