/*
 * @Description: 支付中心状态模块
 */
export default {
	state: {
		payData: {}, //支付单数据
		payList: [], //支付单数据明细
		payMode: [], //支付方式
		isRefresh: false, //是否刷新
		parameter: {}, //上个页面传过来的参数
		homeURL:''//首页的URL
	},
	getters: {
		getPayData(state) {
			return state.payData
		},
		getPayList(state) {
			return state.payList
		},
		getPayMode(state) {
			return state.payMode
		},
		getRefresh(state) {
			return state.isRefresh
		},
		getParameter(state) {
			return state.parameter
		},
		getHomeURL(state) {
			return state.homeURL
		}
	},
	mutations: {
		setPayData(state, data) {
			state.payData = data;
		},
		setPayList(state, data) {
			state.payList = data;
		},
		setPayMode(state, data) {
			state.payMode = data;
		},
		setRefresh(state, data) {
			state.isRefresh = data;
		},
		setParameter(state, data) {
			state.parameter = data;
		},
		setHomeURL(state, data) {
			state.homeURL = data;
		}
	},
	actions: {
		setPayData({
			commit
		}, data) {
			commit('setPayData', data);
		},
		setPayList({
			commit
		}, data) {
			commit('setPayList', data);
		},
		setPayMode({
			commit
		}, data) {
			commit('setPayMode', data);
		},
		setRefresh({
			commit
		}, data) {
			commit('setRefresh', data);
		},
		setParameter({
			commit
		}, data) {
			commit('setParameter', data);
		},
		setHomeURL({
			commit
		}, data) {
			commit('setHomeURL', data);
		}
	}
}
