import RouteBox from '@/components/RouteBox'
import Index from '@/views/HelpCenter/LeftMenuIndex.vue'

export default {
	path: '/HelpCenter',
	name: 'HelpCenter',
	component: Index,
	meta: {
		title: "帮助中心"
	},
	children: [
		
		{
			path: 'HelpCenter',
			name: 'HelpCenter',
			component: () => import('@/views/HelpCenter/HelpCenter.vue'),
			meta: {
				title: "企业简介",
			},
		},
		
	]
}
