<template>
  <div class="about flex-space-center" id="about" name="about">
    <div class="flex-colum">
      <div class="about-header">
        <div class="about-title flex-row font-color-999">
          <span class="">您的位置：</span>
          <breadcrumb />
        </div>
      </div>
      <div class="about-content flex-row">
        <leftMenu></leftMenu>
        <div style="width: calc(1100px - 183px)">
          <router-view />
        </div>
        <!-- <PersonalContent/> -->
      </div>
    </div>
  </div>
</template>
<script>
import MyMarkdown from "../components/MyMarkdown";
import leftMenu from "../components/loyout/leftMenu";
import breadcrumb from "../components/breadcrumb";
import PersonalContent from "./myCenter/PersonalContent";
export default {
  components: {
    MyMarkdown,
    leftMenu,
    breadcrumb,
    PersonalContent,
  },
  methods: {
    myAddressBtn() {
      this.$router.push({
        path: "/myAddress",
        query: "",
      });
    },
    myVehicleBtn() {
      this.$router.push({
        path: "/myVehicle",
        query: "",
      });
    },
    billWalletBtn() {
      this.$router.push({
        path: "/billWallet",
        query: "",
      });
    },
    SendCar() {
      this.$router.push({
        path: "/SendCar",
        query: "",
      });
    },
    MyFocus() {
      this.$router.push({
        path: "/MyFocus",
        query: "",
      });
    },
    MyPersonBtn() {
      this.$router.push({
        path: "/MyPerson",
        query: "",
      });
    },
    HelpCenter() {
      this.$router.push({
        path: "/HelpCenter",
        query: "",
      });
    },
    MoneyDash() {
      this.$router.push({
        path: "/MoneyDash",
        query: "",
      });
    },
    AccountPay() {
      this.$router.push({
        path: "/AccountPay",
        query: "",
      });
    },
  },
};
</script>
<style scoped>
.about {
  width: 100%;
  background: #f8f9fe;
  background: #f8f9fe;
}
/* .about .about-header {
		height: 64px;
		background-color: #fff;
		border-bottom: 2px solid #ff6700;
	} */
.about .about-header .about-title {
  width: 100%;
  margin: 0 auto;
  display: flex;
  font-size: 14px;
  height: 20px;
  background: #f8f9fe;
  padding-top: 15px;
}

.about .content {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
}

.about .content .goods-list {
  margin-left: -13.7px;
  overflow: hidden;
}

/* .about-content {
		width: 1100px;
		margin: 0 auto;
		background: #FFFFFF;
		display: flex;

	} */
::v-deep .el-breadcrumb__inner.is-link {
  font-weight: 400;
  color: #999999;
}
</style>
