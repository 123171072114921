<template>


	<div id="app" name="app" element-loading-text="请稍后">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 除了需要缓存的路由 还要创建一个非缓存路由的入口-->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
		<!-- <stopError v-if="error" :timeOut="timeOut" /> -->
	</div>
</template>
<script>
	import {
		mapGetters,
		mapActions
	} from 'vuex';
	import stopError from './components/stopError.vue'
	export default {
		components:{
			stopError
		},
		data() {
			return {
				error : false,
				loading : true
			};
		},
		computed: {
			...mapActions(["setLogoUrl"]),
		},
		created() {
			this.getHome()
		},
		mounted() {
		},
		methods: {
			// 整合接口  logo  灰色  客服
			getHome() {
				this.ApiRequestPostV7('/api/mall/other/platform-logo/get-home', {
					FShopID: '-1'
				}).then(res => {
					// logo
					this.loading = false
					let logoUrl = res.Obj.FLogoFullPath
					this.$store.commit("setLogoUrl", logoUrl);
					// 灰色
					localStorage.setItem('resultGray',res.Obj.FPageIsGray)
					// 客服
					let str=res.Obj.FService.FRemark.slice(8, -9)
					let funcStr = str;
					let funcTest = new Function('return '+funcStr);
					funcTest()
					
				})
				.catch(err => {})
			},
			timeOut(val) {
				this.error = val
			},
		}
	};
</script>

<style lang="scss">
	// @import '@/style/scss/themeMixin.scss';
	@import '@/style/scss/variable.scss';
	@import '@/style/scss/themeVariable.scss';
	@import '@/style/scss/main.scss';

	.kf_qycn_com_cckf_dock {
		width: 45px !important;
		background-size : cover !important;
		height: 175px !important;
	}

	#app .el-checkbox__inner {
		border-radius: 10px;
	}

	// #app .el-form-item {
	#app .el-input__inner {
		height: 30px;
		line-height: 30px;
		border: 1px solid #f2f2f2;
	}

	#app .el-textarea__inner {
		border: 1px solid #f2f2f2;
	}

	.icon {
		width: 1em;
		height: 1em;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
	.mouse-cover-canvas{
	position: absolute;
	width:325px;
	height:325px;
	top:284px !important;
	left: calc((100vw - 1100px)/2 + 340px ) !important;
	z-index: 99999;
	display: none;
	padding: 10px;
	}
	.mouse-cover-canvas1{
	position: fixed !important;
	width:325px;
	height:325px;
	top:203px !important;
	bottom: 600px !important;
	left: calc((100vw - 1100px)/2 + 340px ) !important;
	z-index: 99999;
	display: none;
	border:1px solid red;
	padding: 10px;
	}
</style>
</style>
