import axios from 'axios';
export async function getImgURLOfBase64(url) {
 var config = {
    method: 'get',
    responseType: 'arraybuffer',
    url: url,
    headers: {
		'token':sessionStorage.getItem("token")
    }
  };
  return await axios(config).then(response => {
    return 'data:image/png;base64,' + btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  })
}
