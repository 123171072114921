<!-- 
引入组件：import imageBox from '@/components/imageBox/imageBox.vue' 
注册组件：
components: {
	imageBox
},

组件使用：<imageBox :src="src"></imageBox>
 -->

<template>
	<el-image @click.native="clickHandler" style='display: flex;align-items: center;justify-content: center;' :style="{width:width+'px',height:height+'px',borderRadius:borderRidus+'px'}" :src="src">
		<div slot="error" class="image-slot flex-row-center-center" style="width: 100%;height: 100%;">
			<i class="el-icon-picture-outline"></i>
		</div>
		<div slot="placeholder" class="image-slot flex-row-center-center" style="width: 100%;height: 100%;">
			加载中<span class="dot">...</span>
		</div>
	</el-image>
</template>

<script>
	export default {
		name: 'imageBox',
		props: {			
			//图片路径
			src: {
				type: String,
				default: ''
			},			
			//图片宽度
			width: {
				type: Number,
				default: null
			},
			//图片高度
			height: {
				type: Number,
				default: null
			},
			//图片圆角
			borderRidus: {
				type: Number,
				default: null
			}
		},
		
		methods: {
			clickHandler: function clickHandler() {
				let prevOverflow = document.body.style.overflow;
			    document.body.style.overflow = '';
			  
			}
		}
	}
</script>

<style>
</style>
