import router from './router'
import store from './store'
import axios from 'axios'
import {
	Message,
	MessageBox
} from 'element-ui'

router.beforeEach(async (to, from, next) => {
	if (from.path == '/') {
		next()
	}



	// 跳过登录验证以及认证验证: 首页、商品列表、商品详情、帮助中心
	if (to.path == '/home' || to.path == '/Goods' || to.path == '/goods/details' || to.path.indexOf('/HelpCenter') != -
		1 ||
		to.path == '/login' || to.path == '/register' || to.path == '/goldMall' || to.path == '/ShopList' || to.path == '/cement' || to.path ==
		'/forget' || to.path == '/LimitedActivities' || to.path == '/LimitedTimeActivities') {
		// next();
		if (to.matched.length === 0) {
			next('/404error');
		} else {
			next();
		}
	} else {
		// console.log("store",store);
		// console.log("store.getters",store.getters);
		// console.log("store.getters.getUserInfo",store.getters.getUserInfo);
		// 判断是否登录
		if (store.getters.getUserInfo == null) {
			// 判断当前是否跳转登录
			if (to.path != '/login') {
				// 此逻辑为 复制一个业务页面URL后到一个新游览器 需要先跳转首页再弹出弹窗
				// if (from.path == '/home') {
				// 	store.dispatch("setShowLogin", true);
				// } else {
				// 	next('/home');
				// }
				// 原逻辑直接弹出
				store.dispatch("setShowLogin", true);
			} else {
				// next();
				if (to.matched.length === 0) {
					next('/404error');
				} else {
					next();
				}
			}
		} else {
			// console.log('store.getters.getUserInfo',store.getters.getUserInfo);
			if ((to.path == "/businessBuy" || to.path == "/applicationPricetab"  || to.path == "/pointPurchase") && store.getters.getUserInfo.fShowPrice == 0) {
				Message({
					showClose: true,
					message: '当前用户不能使用此功能',
					type: 'info',
					duration: 2000
				})
				return;
			}
			// 1、我的地址：/about/myAddress
			// 2、我的车辆：/about/myVehicle
			// 3、发票信息： /about/InvoiceInformation
			// 4、我的员工：/about/MyPerson
			// 5、我的积分：/about/MyPoints
			// 6、申请开店：/OpenShop
			// 7、完善资料审核中: /UnderReview
			// 8、我的账户：/about/MyAccount
			// 9、设置支付密码：/PersonalContent/paymentPassword
			// 10、现金钱包：/PersonalContent/paymentPassword
			// 11、账服通钱包：/PersonalContent/paymentPassword
			if (to.path == "/PersonalContent/myStuff" || to.path == "/PersonalContent/myAddress" || to.path ==
				"/PersonalContent/myVehicle" || to.path == "/PersonalContent/InvoiceInformation" ||
				to.path == "/PersonalContent/MyPerson" || to.path == "/PersonalContent/MyPoints" || to.path ==
				"/PersonalContent/MyAccount" || to.path == "/PersonalContent/ManagementPosition" || to.path == "/OpenShop" || to
				.path == "/PersonalContent/paymentPassword" || to.path == "/businessme/MoneyDash" || to.path ==
				"/businessme/AccountPay") {
				// || to.path == "/UnderReview"
				// 判断登录用户是否认证

				const status = store.getters.getUserInfo.fVerifiedStatus;
				if (status == false) {
					// 此逻辑为 复制一个业务页面URL后到一个新游览器 需要先跳转首页再弹出弹窗
					// if (from.path == '/home') {
					// 	store.dispatch("setShowAttestation", true);
					// } else {
					// 	next('/home');
					// }
					// 原逻辑直接弹出
					// store.dispatch("setShowAttestation", true);

					// 获取最新用户信息 如果用户认证状态为 true 则获取最新token
					// 如果用户认证状态为false 则提示用户去认证
					axios.post('api/mall/ebcustomer/baseinfo/get-current-customer-user-info', {})
						.then(res => {
							if (res.data.obj.fVerifiedStatus == true) {
								store.commit("setUserInfo", res.data.obj);
								// 获取最新token
								axios.post('api/mall/sys/account/refresh-token', {}).then(res => {
										//保存token
										window.sessionStorage.setItem("token", res.data.accessToken);
										localStorage.setItem("token", res.data.accessToken);
										// next();
										if (to.matched.length === 0) {
											next('/404error');
										} else {
											next();
										}
									})
									.catch(err => {

									})
							} else {
								store.dispatch("setShowAttestation", true);
							}
						})
						.catch(err => {

						})
				} else {
					// 登录和认证验证通过则正常跳转
					// next();
					if (to.matched.length === 0) {
						next('/404error');
					} else {
						next();
					}
				}
			} else if (to.path == '/UnderReview' || to.path.indexOf('/PersonalContent/') != -1) {
				// next();
				if (to.matched.length === 0) {
					next('/404error');
				} else {
					next();
				}
			} else {
				// 判断登录用户是否认证
				const status = store.getters.getUserInfo.fVerifiedStatus;
				if (status == false) {
					// 此逻辑为 复制一个业务页面URL后到一个新游览器 需要先跳转首页再弹出弹窗
					// if (from.path == '/home') {
					// 	store.dispatch("setShowAttestation", true);
					// } else {
					// 	next('/home');
					// }
					// 原逻辑直接弹出
					// store.dispatch("setShowAttestation", true);

					// 获取最新用户信息 如果用户认证状态为 true 则获取最新token
					// 如果用户认证状态为false 则提示用户去认证
					axios.post('api/mall/ebcustomer/baseinfo/get-current-customer-user-info', {})
						.then(userInfo => {
							store.commit("setUserInfo", userInfo.data.obj);
							if (userInfo.data.obj.fVerifiedStatus == true) {
								// 获取最新token
								axios.post('api/mall/sys/account/refresh-token', {}).then(tokenRes => {
										//保存token
										window.sessionStorage.setItem("token", tokenRes.data.accessToken);
										localStorage.setItem("token", tokenRes.data.accessToken);
									})
									.catch(err => {

									})
							} else {
								// console.log("res.obj.fVerifiedStatus == false");
								store.dispatch("setShowAttestation", true);
							}
						})
						.catch(err => {

						})
				} else {
					// 登录和认证验证通过则正常跳转

					// fCommerceTypeID  1 个人  2 企业   0 未认证
					// 如果点击企业购 需判断是否企业  不是则不可
					if (store.getters.getUserInfo.fCommerceTypeID.toString() == "1" && to.path == '/businessBuy') {
						Message({
							showClose: true,
							message: '您不是企业用户，不能使用此功能',
							type: 'info',
							duration: 2000
						})
					} else {
						// next();
						if (to.matched.length === 0) {
							next('/404error');
						} else {
							next();
						}
					}
				}
			}
		}
	}

})

router.afterEach(() => {

})

// console.log(router);
