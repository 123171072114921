<template>
	<div :class="themeClass">
		<div class="yijia-footer">
			<div class="yijia-footer-content flex-row">
				<div class="yijia-footer-item flex-row">
					<div class="bgc-blue backgroundcolor-theme text-center">
						<span class="font-color-fff font-size23 font-weight700 line-height46">保</span>
					</div>
					<div class="margin-l-10">
						<div class="font-weight700 font-size16 line-height27">正品保障</div>
						<div class="font-color-999">保证品质 放心选购</div>
					</div>
				</div>
				<div class="yijia-footer-item flex-row">
					<div class="bgc-blue backgroundcolor-theme text-center">
						<span class="iconfont icon-lishi line-height46 font-color-fff font-size23"></span>
					</div>
					<div class="margin-l-10">
						<div class="font-weight700 font-size16 line-height27">购买便捷</div>
						<div class="font-color-999">网上下单 方便快捷</div>
					</div>
				</div>
				<div class="yijia-footer-item flex-row">
					<div class="bgc-blue backgroundcolor-theme text-center">
						<span class="iconfont icon-truck line-height46 font-color-fff font-size23"></span>
					</div>
					<div class="margin-l-10">
						<div class="font-weight700 font-size16 line-height27">送货到家</div>
						<div class="font-color-999">全新上线 代送业务</div>
					</div>
				</div>
				<div class="yijia-footer-item flex-row">
					<div class="bgc-blue backgroundcolor-theme text-center">
						<span class="iconfont icon-10 line-height46 font-color-fff font-size23"></span>
					</div>
					<div class="margin-l-10">
						<div class="font-weight700 font-size16 line-height27">专业客服</div>
						<div class="font-color-999">贴心客服 帮助指南</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="footer-main">
				<div class="ng-promise-box">
					<div class="ng-promise" style="  display: flex;  padding-bottom: 10px; ">
						<div style="width: 120px; text-align: center">
							<img style="width:92px;height:93px;" src="../../assets/u42.jpg" alt="">
							<p class="color-999 font-size13">关注</p>
							<p class="color-999 font-size13">聚材通平台公众号</p>
						</div>
						<div style="width: 40%;text-align: center">
							<!-- <div style="margin-bottom: 26px; font-size: 32px;color:#0173FE;font-weight: bold;">LOGO展示</div> -->
							<div style="margin-top: 36px;">
								<p class="color-999 font-size13">
									客服电话（周一至周五 9:00-22:00）
								</p>
								<div class="flex-row-center-center margin-t-5 margin-r-5">
									<div class="LandlineImage margin-r-10">
										<imageBox :src="require('@/assets/Landline2.gif')"></imageBox>
									</div>
									<p class="color-999 font-size24 font-weight700">400-666-3439</p>
								</div>
							</div>
						</div>
						<div class="color-999" style="display: flex; width: 50%;">
							<div v-for="(item, index) of footerItem" :key="index" style="width: 30%; text-align: left">
								<span class="color-999 font-size14"
									style="font-weight: 600; line-height: 2;">{{ item.title }}</span>
								<div v-for="(i, indexs) of item.child" :key="indexs" style="line-height: 2;"
									@click="handler(i.url,i.id,i.title)" class="pointer color-999">
									{{ i.title }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="github">
					<a href="https://github.com/hai-27/vue-store" target="_blank">
						<div class="github-but"></div>
					</a>
				</div>

			</div>
			<div class="mod_help ">
				<div class="footer-main">
					<!-- 	<p style="margin-bottom: 3px">
					<router-link to="/AboutJuCai">关于聚材通</router-link>
					<span>|</span>
					<router-link to="/goods">联系我们</router-link>
					<span>|</span>
					<router-link to="/about">手机商城</router-link>
				</p> -->
					<p class="coty font-color-999" style="margin-top: 0; margin-bottom: 0">
						新ICP备16002974号-1 新疆聚材电子商务有限公司 版权所有 新公网安备
						65010602000130号 自治区党建备案编号 第376号
					</p>
					<!-- <p>技术支持:新疆恒和久远信息技术有限公司</p> -->
					<div class="flex-row-align-center margin-t-10">
						<div class="flex-row-start-start pointer" @click="goWebPolice">
							<imageBox :src="require('@/assets/footer/u225.png')" :width='30' :height='27'></imageBox>
							<div class="font-size12 font-color-999 margin-l-10 text-box-height">
								<div>网络警察</div>
								<div>提醒您</div>
							</div>
						</div>
						<div class="flex-row-start-start pointer margin-l-10" @click="goReportCenter">
							<imageBox :src="require('@/assets/footer/u218.png')" :width='30' :height='27'></imageBox>
							<div class="font-size12 font-color-999 margin-l-10 text-box-height">
								<div>中国互联网</div>
								<div>举报中心</div>
							</div>
						</div>
						<div class="flex-row-start-start pointer margin-l-10" @click="goReportDownload">
							<imageBox :src="require('@/assets/footer/u222.png')" :width='30' :height='27'></imageBox>
							<div class="font-size12 font-color-999 margin-l-10 text-box-height">
								<div>网络举报</div>
								<div>APP下载</div>
							</div>
						</div>
						<div class="flex-row-start-start pointer margin-l-10" @click="goRealName">
							<imageBox :src="require('@/assets/footer/u228.png')" :width='30' :height='27'></imageBox>
							<div class="font-size12 font-color-999 margin-l-10 text-box-height">
								<div>实名认证</div>
							</div>
						</div>
						<a
							class="flex-row-start-start pointer margin-l-10"
							href="https://xyt.xcc.cn/getpcInfo?sn=1522418993736925184&language=CN&certType=8&url=www.xjjcjc.com" 
							target="_blank" 
							style="position: relative; text-decoration: none"
						>
							<div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
								<embed 
									src="https://program.xinchacha.com/web/1522418993736925184=www.xjjcjc.com.svg"
									width="90" 
									height="30"
									type="image/svg+xml" 
									pluginspage="//www.adobe.com/svg/viewer/install/" />
							<div class="font-size12 font-color-999 margin-l-10 text-box-height">
								<div>网络安全</div>
								<div>证书</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<agreementDialog :iContents='iContent' :configshow='configshow' ref="areDialog" :type="footer" />
	</div>
</template>

<script>
	import imageBox from '@/components/imageBox/imageBox.vue'
	import footer from "./publicList.js";
	import agreementDialog from '@/components/agreementDialog';
	import {
		mapGetters,
		mapMutations
	} from "vuex";
	export default {
		name: "Footer",
		components: {
			imageBox,
			agreementDialog
		},
		data() {
			return {
				iContent: '',
				configshow: false,
				footerItem: footer.footerItem,
				footer: 'footer',
			};
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			to(url, id) {
				if (url == "/OpenShop") {
					if (this.getUserInfo != null) {
						if (this.getUserInfo.fCommerceTypeID != '2') {
							this.$message('个人用户不能开店');
							return;
						}
					}
				}
				if (url == "/CarrierRegistration") {
					if (this.getUserInfo != null) {
						if (this.getUserInfo.fCommerceTypeID != '2') {
							this.$message('个人用户不能成为承运商');
							return;
						}
					}
				}
				if (url == "xieyitanchuang") {
					this.iContent = id;
					this.$refs.areDialog.show();
					this.configshow = true;
					return;
				}
				this.$router.push(url);
			},
			
			handler(url, id, title) {
        window.scrollTo(0, 0);
				if(this.getUserInfo == null && url == "/OpenShop" || this.getUserInfo == null && url == "/CarrierRegistration"
				|| this.getUserInfo == null && url == "/ElectronicContract"){
					if(this.$route.fullPath !="/login" && this.$route.fullPath !="/register"){
						this.$router.push('/top')
					}
				}
				if (url == "/OpenShop" && this.getUserInfo.fCommerceTypeID != '2') {
							this.$message('个人用户不能开店');
							return;
				}
				if(url == "/OpenShop" && this.getUserInfo.fCommerceTypeID == '2'){
					this.$router.push({path: '/OpenShop'})
					return;
				}
				if (url == "/CarrierRegistration" && this.getUserInfo.fCommerceTypeID != '2') {
							this.$message('个人用户不能成为承运商');
							return;
				}
				if(url == "/CarrierRegistration" && this.getUserInfo.fCommerceTypeID == '2'){
					this.$router.push({path: '/CarrierRegistration'})
					return;
				}
				if (url == "/ElectronicContract" && this.getUserInfo.fCommerceTypeID != '2') {
							this.$message('个人用户不能申请线上合同');
							return;
				}
				if(url == "/ElectronicContract" && this.getUserInfo.fCommerceTypeID == '2'){
					this.$router.push({path: '/ElectronicContract'})
					return;
				}
				if (url == "xieyitanchuang") {
					this.iContent = id;
					this.$refs.areDialog.show();
					this.configshow = true;
					return;
				}
				if ('/HelpCenter/HelpCenter' === this.$route.path) {
					location.reload()
				} else {
					this.$router.push({
						path: '/HelpCenter/HelpCenter'
					})
				}
				if(title == "常见问题" ){
					title = "购买问题"
					localStorage.setItem('title', title)
					return
				}
				if(title == "视频教学" ){
					title = "店铺入驻"
					localStorage.setItem('title', title)
					return
				}
				localStorage.setItem('title', title)
			},
			//网络警察跳转
			goWebPolice() {
				window.open("http://www.cyberpolice.cn/wfjb/", "_blank");
			},
			//中国互联网举报中心
			goReportCenter() {
				window.open("https://www.12377.cn/", "_blank");
			},
			// 网络举报APP下载
			goReportDownload() {
				window.open("https://www.12377.cn/node_548446.htm", "_blank");
			},
			//实名认证
			goRealName() {
				window.open("https://si.trustutn.org/info?sn=531200214039184258147&certType=1", "_blank");
			}

		},
	};
</script>

<style scoped lang="scss">
	/* 底栏容器CSS */
	.footer {
		width: 100%;
		text-align: center;
		font-size: 12px;
		padding-top: 30px;
		/* padding-bottom: 30px; */

		background-color: rgba(51, 51, 51, 1);
	}

	.color-999 {
		color: #ccc;
	}

	.footer-main {
		/* padding: 20px 0; */
		max-width: 1100px;
		min-width: 1100px;
		margin: 0 auto;
	}

	.footer .ng-promise-box {
		/* line-height: 145px; */
	}

	.footer .ng-promise-box {
		margin: 0 auto;

		/* line-height: 145px; */
	}

	.footer .ng-promise-box .ng-promise p a {
		color: #333;
		font-size: 20px;
		margin-right: 210px;
		padding-left: 44px;
		height: 40px;
		display: inline-block;
		line-height: 40px;
		text-decoration: none;
	}

	.footer .github {}

	.footer .github .github-but {
		width: 50px;

		margin: 0 auto;
	}

	.footer .mod_help {
		text-align: left;
		color: #888888;
		margin-top: 8px;
		background-color: #262626;
		height: 75px;
		padding-top: 20px;
	}

	.footer .mod_help p {
		line-height: 1.5;

	}

	.footer .mod_help p a {
		color: #888888;
		text-decoration: none;
	}

	.footer .mod_help p a:hover {
		color: #fff;
	}

	.footer .mod_help p span {
		padding: 0 4px;
	}

	.yijia-footer {
		width: 100%;
		background: #ffffff;

		.yijia-footer-content {
			width: 1100px;
			height: 46px;
			padding: 15px 0 15px 0;
			margin: auto;

			.yijia-footer-item {
				width: calc(100% / 4);
				height: 46px;
				padding-left: 47px;

				.bgc-blue {
					width: 46px;
					height: 46px;
					border-radius: 13px;
				}
			}
		}
	}

	.line-height46 {
		line-height: 46px;
	}

	.line-height23 {
		line-height: 23px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.backgroundcolor-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.LandlineImage {
		width: 25px;
		height: 25px;
	}

	.text-box-height {
		height: 32px;
	}
</style>
