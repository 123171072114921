//登录之后如果未认证，重新获取用户信息，如果fVerifiedStatus为true，就是已经完成验证，就可以重新获取token，然后存起来
import store from '@/store/index.js'
import axios from 'axios'
export function getUserNews() {
	// 获取用户信息
	axios.post('api/mall/ebcustomer/baseinfo/get-current-customer-user-info', {}).then(res => {
			// 判断是否认证
			if (res.obj.fVerifiedStatus) {
				// 已认证拿取最新token
				axios.post('api/mall/sys/account/refresh-token', {}).then(res => {
						// 保存token
						window.sessionStorage.setItem("token", res.accessToken);
						localStorage.setItem("token", res.accessToken);
					})
					.catch(err => {

					})
			} else {
				// 未认证 调起认证弹窗 引导用户去认证
				store.dispatch("setShowAttestation", true);
			}
		})
		.catch(err => {

		})
}

export default {
	getUserNews
}
