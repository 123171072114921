<template>
	<div>
		<el-dialog v-dialogDrag :visible.sync="copyStatus" width="47%"
			 :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false"
			append-to-body>
			<div style="height:calc(60vh - 210px);position: relative;overflow: hidden;overflow-y: auto;width: 100%;">
				<div class="errorTitle">关于年底扎帐时间和系统维护的通知</div>
				<div class="firstLine">尊敬的聚材通用户：</div>
				<div class="errorContent">平台将于本月<span class="dateRed">12月30号24：00</span>扎帐并开具发票，12月31日进行邮寄。并于<span class="dateRed">12月31日00：00-24：00</span>进行系统维护，
				届时平台APP、web端将无法登录及进厂装车，有需求的客户请提前或1月1日以后进厂装车，给您带来的不便还请见谅！</div>
			    <div class="footer">
			    	<div class="">聚材通平台</div>
			    	<div class="">2022-12-22</div>
			    </div>
			</div>
			
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				copyStatus : true
			}
		},
		methods:{
			handleCloseCopy(){
				this.$emit('timeOut',false)
			}
		}
	}
</script>

<style scoped>
	.errorTitle{
		font-size: 22px;
		color:   #4b8254;
		margin-bottom: 30px;
	}
	.dateRed{
		color:#E73838;
		font-weight: 600;
	}
	.errorContent,.firstLine{
		color:#666;
		font-size: 22px;
		line-height: 35px;
	}
	/* .firstLine{
		text-indent: 1em;
	} */
	.errorContent{
		text-indent: 2em;
	}
	.footer{
		position: absolute;
		right: 10px;
		bottom: 0;
		font-size: 18px;
		line-height: 2;
		text-align: right
	}
	.el-dialog__wrapper {
		display: flex;
		align-items:center;
	}
	
	/deep/ .el-dialog {
		position: relative;
		/* background-image: url(./assets/bgimg/dialog%20.png); */
		filter: alpha(opacity=70);
		background-repeat: no-repeat;
		background-size: 70% 18%;
		-moz-background-size: 70% 18%;
		margin-top: 0 !important;
		height: 45%;
	}
	/deep/ .el-dialog::before {
		content: "";
		position: absolute;
		top: -1px;
		left: -1px;
		width: 100%;
		height: 100%;
		/* background-image: url(./assets/bgimg/dialog%20.png); */
		filter: alpha(opacity=70);
		background-repeat: no-repeat;
		background-size: 70% 18%;
		-moz-background-size: 70% 18%;
		transform: rotate(180deg);
	}
	
	/deep/ .el-dialog__body {
		/* min-height: 76%; */
		padding:0px  25px
	}
	
	/deep/ .el-dialog__footer {
		position: inherit;
	}
</style>
