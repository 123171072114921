<!-- 悬浮框 -->
<template>
	<div :class="themeClass" class="themeClass">
		<div class="suspension-box font-color-fff font-size12">
			<router-link to="/shoppingCart">
				<el-badge :value="getCarCount" :hidden="getCarCount == 0?true:false" :max="99" class="item">
					<div class="suspension-item flex-column-center-center pointer font-color-fff">
						<div class="iconfont icon-gouwuche1 font-size20 margin-b-5"></div>
						<div>购物车</div>
					</div>
				</el-badge>
			</router-link>
			<div class="suspension-item flex-column-center-center pointer" @mouseenter="teleJCShow=true" @mouseleave="teleJCShow=false">
				<div class="iconfont icon-ico font-size20 margin-b-5"></div>
				<div>二维码</div>
				<div v-if="teleJCShow" class="teleJC">
					<div class="teleJC-item flex-row">
						<div class="piccode flex-row-center-center">
							<!-- <div class="ju radius5 bg-theme font-color-fff font-size13 text-center font-weight700">JU</div> -->
						</div>
						<div class="teleJC-item-right margin-l-10">
							<div class="font-color-ff6666 font-size13">下载聚材通手机App</div>
							<div class="color-theme font-size13">请前往各手机应用市</div>
							<div class="color-theme font-size13">场内搜索“聚材通”</div>
							<div class="color-theme font-size13">下载</div>
							<!-- <div class="font-color-666 font-size12">适用:安卓、iOS、iPad</div> -->
						</div>
					</div>
					<div class="teleJC-item flex-row">
						<div class="pic flex-row-center-center">
							<!-- <div class="ju radius5 bg-theme font-color-fff font-size13 text-center font-weight700">JU</div> -->
						</div>
						<div class="teleJC-item-right margin-l-10">
							<div class="font-color-000 font-size13">关注聚材通微信</div>
							<div class="color-theme font-size12">微信扫一扫关注聚材通服</div>
							<div class="color-theme font-size12">务号,更多优惠福利</div>
						</div>
					</div>
					<!-- <div class="teleJC-item flex-row">
						<div class="pic flex-row-center-center">
							<div class="ju radius5 bg-theme font-color-fff font-size13 text-center font-weight700">JU</div>
						</div>
						<div class="teleJC-item-right margin-l-10">
							<div class="font-color-000 font-size13">聚材通商家</div>
							<div class="color-theme font-size12">平台商家管理更健康</div>
							<div class="font-color-666 font-size12">适用:安卓、iOS、iPad</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="to-top font-color-fff flex-row-center-center pointer font-size13" @click="returnTop">置顶</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "Suspension",
		data() {
			return {
				teleJCShow: false,
				top: 400
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo', 'getCarCount']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			// window.addEventListener('scroll', this.windowScroll)
		},
		methods: {
			returnTop() {
				content.scrollIntoView()
			},
			windowScroll() {
				// 以下写法原生兼容
				// let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				// let scrollBottom = document.body.scrollHeight - document.documentElement.clientHeight - scrollTop;
				// console.log(document.body.scrollHeight);
				// let maxBottom = document.body.scrollHeight - 576;
				// this.top = 400 + scrollTop;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	
	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.suspension-box {
		z-index: 1000000;
		position: fixed;
		right: 0;
		width: 45px;
		top: 350px;

		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.item ::v-deep {
		.el-badge__content.is-fixed {
			right: 55px;
		}
	}

	.to-top {
		width: 45px;
		height: 45px;
		background-color: #000000;
	}

	.suspension-item {
		height: 60px;
		width: 45px;
	}

	.teleJC {
		position: absolute;
		right: 55px;
		top: 0px;
		width: 256px;
		// height: 248px;
		border: 1px solid #e4e4e4;
		background-color: #F8F9FE;
		z-index: 1000;
	}

	.teleJC-item {
		margin: 10px;
		margin-right: 0;
	}

	.pic {
		width: 95px;
		height: 95px;
		background: url('../../assets/u42.jpg') no-repeat;
		background-size: 100%;
	}
	
	.piccode {
			width: 95px;
			height: 95px;
			background: url('../../assets/jcadmin_img.png') no-repeat;
			background-size: 100%;
		}
	
	.ju {
		width: 22px;
		height: 22px;
		line-height: 22px;
	}

	.teleJC-item-right div {
		line-height: 17px;
		text-align: left;
	}

	.teleJC-item-right div:nth-child(1) {
		margin-bottom: 10px;
	}

	.teleJC-item-right div:nth-child(2) {
		margin-bottom: 3px;
	}

	.teleJC:before {
		content: "";
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid #e4e4e4;
		width: 0px;
		height: 0px;
		position: absolute;
		right: -10px;
		top: 85px;
	}

	.teleJC:after {
		content: "";
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid #F8F9FE;
		width: 0px;
		height: 0px;
		position: absolute;
		right: -9px;
		top: 85px;
	}
</style>
