<template>
	<div id="storeLogin">
		<el-dialog v-if="isLogin" title="登录" width="773px" center :visible.sync="isLogin" :close-on-click-modal="false"
		 :close-on-press-escape="false">
			<loginDialog  :closeStaus="closeStaus" />
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import loginDialog from '@/components/login/loginDialog';
	export default {
		name: "StoreLogin",
		components: {
			loginDialog
		},
		data(){
			return{
				closeStaus:true
			}
		},
		computed: {
			// 获取vuex中的showLogin，控制登录组件是否显示
			isLogin: {
				get() {
					return this.$store.getters.getShowLogin;
				},
				set(val) {
					this.setShowLogin(val);
				}
			}
		},
		methods: {
			...mapActions(["setUser", "setShowLogin"]),
		}
	};
</script>

<style>
	.forminp {
		width: 100%;
	}

	.testf .el-input__inner {
		border-radius: 20px !important;
		width: 100% !important;
		height: 40px !important;
		background-color: rgba(246, 246, 246, 1);
		border: 0;
	}

	.testf2 .el-input__inner {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		height: 40px !important;
		width: 100% !important;
		background-color: rgba(246, 246, 246, 1);
		border: 0;
	}

	.testf2,
	.el-input-group--prepend .el-input__inner,
	.el-input-group__append {
		border: 0;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	#storeLogin .el-dialog--center .el-dialog__body {
		padding: 0;
	}

	#storeLogin .el-dialog__body {
		padding: 0;
	}

	#storeLogin .el-dialog__header {
		display: none;
	}
	#storeLogin .el-dialog, .el-pager li{
		border-radius: 20px;
	}
</style>

