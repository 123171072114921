import Vue from 'vue';
let  strb = sessionStorage.getItem("setMenBList");
let  strp = sessionStorage.getItem("setMenPList");
 // v-menucom ="{args:i.url,type:1}"
if(strp){
	let  setMenPList =JSON.parse(sessionStorage.getItem("setMenPList"));
	let  setMenBList =JSON.parse(sessionStorage.getItem("setMenBList"));
}
Vue.directive('menucom', {
    bind(el, binding, vnode, oldVnode) {
		if(strp && strb){
			let args = binding.value.args;
			let type = binding.value.type;
			if(type==1){
				if(args!=''){
					if(!setMenPList.find(x=>x.fApplicationName==args)){
						el.style="display:none";	
					}
				}
			}
			if(type==2){
				if(args!=''){
					if(!setMenBList.find(x=>x.fApplicationName==args)){
							el.style="display:none";	
					}
				}
			}
		}
		
	}
});