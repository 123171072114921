<template>
	<div class="backwr">
		<div >
			<div class="divra1  oneline-show-hide ">
				<span class="font-colr-9 margin-l-10 margin-r-5"><i class="el-icon-time"></i></span>
				<span class="font-size12 font-colr-9">最近搜索：</span>			
				<span v-for="(item,index) of latelyData" :key="index" class="font-size12 margin-r-5"> 
					{{item.name}}
				</span>
			</div>
			<div style="padding:10px">
				<div class="font-size12 font-weight700 margin-bootom">附近生产厂</div>
				<div class="margin-bootom dis-f">
					<div class="divra" v-for="(item,index) of productionData" :key="index"> 
							{{item.name}}
					</div>
				</div>
				<div class="">
						<div class="font-size12 font-weight700 margin-bootom6">热门店铺</div>
						<div class="fnt"  v-for="(item,index) of shopData" :key="index"> 
							{{item.name}}
						</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
	  name: "seach",
	  data(){
		  return{
			  latelyData:[{
				  id:'1',
				  name:'水泥'
			  },{
				 id:'2',
				 name:'乌鲁木齐' 
			  },{
				 id:'3',
				 name:'天山水泥' 
			  },{
				 id:'4',
				 name:'普通硅酸盐水泥' 
			  },{
				 
				  id:'5',
				  name:'商砼' 
			  },
			  ],
			  productionData:[{
				  id:'1',
				  name:'达坂城生产厂'
			  },
			  {
			  	    id:'2',
			  	    name:'达坂城生产厂'
			  },
			  {
			  		id:'3',
			  		name:'达坂城生产厂'
			  },
			  {
			  		id:'4',
			  		 name:'达坂城生产厂'
			  }],
			  shopData:[{
				   id:'1',
				   name:'乌鲁木齐市啦啦啦啦建材有限公司'
			  },
			  {
			  	    id:'2',
			  	    name:'乌鲁木齐市啦啦啦啦建材有限公司'
			  },
			  {
			  		id:'3',
			  		name:'乌鲁木齐市啦啦啦啦建材有限公司'
			  },
			  {
			  		id:'4',
			  		name:'乌鲁木齐市啦啦啦啦建材有限公司'
			  }],
		  }
	  },
	  methods: {
		  
	  }
	}
</script>

<style scoped="scoped" lang="scss">
	.dis-f{
		display:flex;
	}
	.margin-bootom{
		margin-bottom:10px;
	}
	.margin-bootom6{
		margin-bottom:5px;
	}
	.backwr{
		background-color: #fff;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(221, 235, 255, 1);
		position: absolute;
		z-index: 999;
	}
	.divra1{
		   background-color: rgba(248, 249, 254, 1);
		    box-sizing: border-box;
		    border-width: 1px;
		 
			border-bottom-style: solid;
		    border-color: rgba(221, 235, 255, 1);
			line-height: 28px;
			    height: 28px;
		 
	}
	.divra{
		background-color: rgba(245, 248, 254, 1);
		font-size: 12px;
		color: #999999;
		padding: 5px;
		margin-left: 5px;
		border-radius: 20px;
	}
	.fnt{
		font-size: 12px;
		    color: #999999;
		    text-align: left;
			line-height: 20px;
	}
	.font-colr-9{
		    color: #999999;
	}
</style>
