import axios from 'axios';
var config = {

	// "UPLOAD_FILE_ASHX": "http://192.168.201.34:51045/api/henghe/blob/upload",
	"UPLOAD_FILE_ASHX": '',
	// "PICTURE_SHOW_SERVER": ''
	"PICTURE_SHOW_SERVER": "http://192.168.205.35:51045/api/henghe/blob/"
}
// 生成上传文件所需的 action URL
export function makeUploadActionURL(dirpath, options) {
	var op = {
		dir: 'file'
	};
	Object.assign(op, options);
	var result = options.ashx;
	return result;
}

// 处理上传结果
export function parseResult(response, uploadFileAshx) {
	if (response.fileName) {
		return {
			success: true,
			filename: response.fileName,
			url: uploadFileAshx + response.fileName
		};
	} else {
		return {
			success: false,
			message: response.message
		};
	}
}

// 拼接相对路径和文件服务器地址
export function getUploadFileURL(path) {

	// let server = config.PICTURE_SHOW_SERVER;
	// if (server.endsWith('/')) {
	// 	server = server.slice(0, server.length - 1);
	// }
	// if (!path.startsWith('/')) {
	// 	path = '/' + path;
	// }
	// return server + path;
	return "123123123"
}

export function getUploadFileAshx() {
	return config.UPLOAD_FILE_ASHX;
}

export default {
	makeUploadActionURL,
	parseResult,
	getUploadFileURL,
	getUploadFileAshx
}
