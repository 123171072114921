<template>
	<div :class="themeClass" class="pt20">
		<el-menu :default-active="defaultactive" class="el-menu-vertical-demo menu-width" :default-openeds="opends"
			@open="handleOpen" @close="handleClose">
			<div class="all-menu-title background-color-theme flex-row-center-center font-size13">常见问题分类</div>
			<el-submenu :index="index + '' " v-for="(i,index) of mainitems" :key="index">
				<template slot="title">
					<div class="font-color-333">{{i.fHelpCenter}}</div>
				</template>
				<div class="menuchild">
					<div class="menuchild-item font-size14 pointer"
						:class="index == 0 && indexs == 0 && flag || j.fPath==varble?'menuchild-item-select':'font-color-666 padding-left26'"
						v-for="(j,indexs) of i.childItems" :key="indexs" @click="getUrl(j)">
						{{j.fHelpCenter}}
					</div>
				</div>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapState,
		mapMutations
	} from 'vuex';
	import parmsd from '@/views/HelpCenter/params'
	export default {
		name: 'LeftMenus',
		fBillID: "",
		data() {
			return {
				varble: '',
				selectId: '',
				fHelpCenter: "",
				fPath: "",
				fBillID: "",
				fContentPath: "",
				flag: true,
				opends: ["0"],
				defaultactive: '',
				items: [],
				mainitems: [],
				myRoute: {},
				title: localStorage.title,
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			activeMenu() {
				return this.$route.path;
			}
		},
		mounted() {
			this.getHelpcenterlist()
		},
		methods: {
			calledMethod() {
				var able = null;
				if (this.title != undefined && this.title != '') {
					for (var i = 0; i < this.items.length; i++) {
						if (this.items[i].fHelpCenter == this.title) {
							able = this.items[i];
						}
					}
					if (able != undefined && able != null) {
						this.getUrl(able);
					}
				}
			},
			getHelpcenterlist() {
				this.ApiRequestPostNOMess('/api/mall/auxiliary/help-center/get-list', {}).then(
					result => {
						let arr = []
						let arr1 = []
						this.mainitems = result.obj.items.filter(x => x.fParentID == -1);
							this.items = result.obj.items.filter(x => x.fBillID != -1 && x.fParentID != -1);
						for (let i of this.mainitems) {
							i.childItems = this.items.filter((x,k) => {
								if(x.fParentID == i.fBillID){
									arr.push(x)
									arr1.push(k)
									return x
								}
							});
							i.childItems.map(item=>{
								if(item.fHelpCenter == this.title){
									this.mainitems.map((item,index)=>{
										if(item.childItems == i.childItems){
											this.opends = [index + '']
										}
									})
								}
							})
						}
						this.calledMethod()
						let route = this.$route
						if (route.query && route.query.id == 1) {
							this.mainitems.forEach((item, index) => {
								if (item.fBillID == 1814193200) {
									this.opends = [index + '']
									item.childItems.forEach(item1 => {
										if(item1.fBillID == 1814195600) {
											this.getUrl(item1)
										}
									})
								}
							})
						}
					},
					rej => {}
				)
			},
			handleOpen(key, keyPath) {},
			handleClose(key, keyPath) {},
			getUrl(val) {
				this.flag = false
				this.varble = val.fPath;
				var sub = val.fPath.substring(5).replace(/\\/g, ' > ')
				this.$bus.$emit('type', {
					fContentPath: val.fContentPath,
					fPath: sub
				});
				localStorage.removeItem('title')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.all-menu-title {
		height: 42px;
		margin: 5px;
	}

	.menuchild-item-select {

		font-weight: bold;
		padding-left: 23px;

		@include themify($themes) {
			border-left: 3px solid themed('themes_color');
			color: themed('themes_color');
		}
	}

	.padding-left26 {
		padding-left: 26px;
	}

	.pt20 {
		margin-top: 20px;
		width: 170px;
		margin-right: 15px;

		.menu-width {
			background-color: rgba(248, 249, 254, 1);
			// width: 100%;
			border-right: 0;
			min-height: 650px;
			min-width: 170px;
			padding-bottom: 5px;

			.el-menu {
				background-color: rgba(248, 249, 254, 1);

				.menuchild {
					background-color: #fff;
					margin: -5px 5px 0px 5px;

					.menuchild-item {
						line-height: 35px;
						height: 35px;
					}
				}

			}

			.el-submenu .el-submenu__title {
				line-height: 40px;
				height: 40px;
				margin: 5px;
				background-color: #ebeefc;
				border-radius: 5px;
			}

			.el-menu-item {
				line-height: 40px;
				height: 40px;
				margin: 5px;
				background-color: #fff;
			}

			.el-icon-arrow-down:before {
				content: "\e6df" !important;
				font-size: 16px;
				font-weight: 600;
			}
		}

		.border:last-child {
			border-bottom: 1px solid #f2f2f2;
		}

		.ma-botn {
			margin-top: 10px;
			margin-right: 13px;
			background-color: #fff;
		}

		.border {

			border-left: 1px solid #f2f2f2;
			border-right: 1px solid #f2f2f2;
			border-top: 1px solid #f2f2f2;
			width: 142px;
			height: auto;
		}

		.titlefont {
			width: 65px;
			height: 19px;
			font-size: 16px;

			font-weight: bold;
		}

		.padding-13 {
			padding: 13px;
		}

		.pading-l-30 {
			font-size: 14px;
			padding-left: 17px;
			padding-top: 10px;
			cursor: pointer;
		}
	}

	.menu-title {
		background-color: #0000FE;
	}
</style>
