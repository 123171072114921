<!-- RichText
  富文本渲染组件

  props:
    html: 传入要渲染的 html 字符串

-->

<template>
  <RichTextSimple :html="html" />
</template>

<script>
import RichTextSimple from './RichTextSimple'

export default {
  name: "RichText",
  components: {
    RichTextSimple
  },
  props: {
    html: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
