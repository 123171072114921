<!-- RichTextSimple
  简单富文本渲染组件

  直接使用 vue 的 v-html 进行渲染

  props:
    html: 传入要渲染的 html 字符串

-->

<template>
  <div class="richtext-box" v-html="html"></div>
</template>

<script>
export default {
  name: "RichTextSimple",
  props: {
    html: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.richtext-box {

}
</style>
