/*
 * @Description: 用户登录状态模块
 * @Author: hai-27
 * @Date: 2020-02-19 17:42:11
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-26 23:14:32
 */
export default {
	state: {
		user: null, // 登录的用户
		showLogin: false, // 用于控制是否显示登录组件
		showAttestation: false, // 用于控制是否显示用户认证组件
		userInfo: null,
		menBList: [], //菜单数据我的业务
		menPList: [], //菜单数据个人中心
		authenticationUserInfo: null, //客商信息
		carCount: 0, //用户信息
		count: 0, //消息提示
		// currentMenu: null,
		// menu: []

	},
	getters: {
		getUser(state) {
			// if (!state.user) {
			//    state.user = JSON.parse(sessionStorage.getItem("setUserInfo"))
			// }
			return state.user
		},
		getShowLogin(state) {
			return state.showLogin
		},
		getUserInfo: state => {
			return state.userInfo
		},
		getMenBList: state => {
			return state.menBList
		},
		getMenPList: state => {
			return state.menPList
		},
		getAuthenticationUserInfo: state => {
			return state.authenticationUserInfo
		},
		getCarCount: state => {
			return state.carCount
		},
		getShowAttestation(state) {
			return state.showAttestation
		},
		getCount(state) {
			return state.count
		},
	},
	mutations: {
		getUser(state) {
			if (!state.user) {
				state.user = JSON.parse(sessionStorage.getItem("setUserInfo"))
			}
			return state.user
		},
		setUser(state, data) {
			state.user = data;
		},
		setShowLogin(state, data) {
			state.showLogin = data;
		},
		setShowAttestation(state, data) {
			state.showAttestation = data;
		},
		setUserInfo(state, param) {
			state.userInfo = param;

		},
		setMenBList(state, param) {
			state.menBList = param;

		},
		setMenPList(state, param) {
			state.menPList = param;

		},
		setCarCount(state, param) {
			state.carCount = param;
		},
		setAuthenticationUserInfo(state, param) {
			state.authenticationUserInfo = param;
		},
		setCount(state, param) {
			state.count = param;
		},
		// selectMenu(state, val) {
		// 	if (val.name !== 'home') {
		// 		state.currentMenu = val
		// 		console.log(state.currentMenu,'state.currentMenu')
		// 	} else {
		// 		state.currentMenu = null
		// 	}
		// }
	},
	actions: {
		setUser({
			commit
		}, data) {
			commit('setUser', data);
		},
		setShowLogin({
			commit
		}, data) {
			commit('setShowLogin', data);
		},
		setShowAttestation({
			commit
		}, data) {
			commit('setShowAttestation', data);
		},
		setCount({
			commit
		}, data) {
			commit('setCount', data);
		}
	}
}
