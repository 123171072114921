<template>
	<div :class="themeClass" class="ma-botn">
		<div class="padding-13 border  " v-for="(item,index) of items" :key="index" >
			<span class="titlefont"  >{{item.FApplicationText}}</span>
			<div v-for="(i,indes) of item.children" :key="indes" class="pading-l-30" @click="getUrl(i)" >
				<router-link :class="selectId==i.FApplicationID?'color-theme font-weight700':''" :to="i.FApplicationName">{{i.FApplicationText}}</router-link>
			</div>
			<div class="pading-l-30" v-if='item.FApplicationText=="账户设置" && getUserInfo != null'  @click="cancellationOpen">注销账号</div>
		</div>
		<!--展示注销的弹框-->
		<el-dialog
		  title="警告"
		  :visible.sync="dialogVisible"
		  width="30%"
		  :before-close="handleClose">
		  <span>{{this.byecontent}}</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="handleClose">取 消</el-button>
		    <el-button type="primary" @click="nextbu()">{{showconfirmtext}}</el-button>
		  </span>
		</el-dialog>
		<!--展示注销时你的账户还有哪些东西值得你再看看-->
		<el-dialog
		  title="警告"
		  :visible.sync="lateVisible"
		  width="50%"
		  :before-close="lateClose"
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  >
		  <div style="line-height: 2;font-size: 16px;">{{ flag ? this.latecontent : '当前账号未满足注销条件，请查询详细信息：' }}
			<div v-if="!flag" style="display: inline-block;color: #FF6600;">(<span style="color: red;">*</span>项代表必须处理完成的账务或业务)</div> 
			<div v-if="flag" style="display: inline-block;color: #FF6600;">(注销则代表放弃以下权益)</div>
		  </div>
		  <el-table :data="tableData" highlight-current-row  border  ref="multipleTable" :header-cell-style="{ 'text-align': 'center', background: '#F7F9FE' }" >
		  	<el-table-column type="index" width="50" align="center" fixed />
		  	<el-table-column v-for="(item, index) in tableLabel" :key="index" :prop="item.prop" :width="item.width" :label="item.label"
		  	 :align="item.align" :fixed="item.fixed" show-overflow-tooltip>
			 <template slot-scope="scope">
				 <span v-if="scope.row.FIsCancel == '0' && item.label == '项目'" style="color: red;">*</span>{{ scope.row[item.prop] }}
			 </template>
			 </el-table-column>
		  </el-table>
		  <!-- <el-form ref="form" label-width="140px" label-position="left"> -->
		  	<!-- <el-form-item v-for="(item,index) in tableData" :label="item.FItem + '：'">
		  		<el-input v-model="item.FEndBalance" disabled />
		  	</el-form-item> -->
		  	<!-- <el-form-item label="任务标题" prop="fTitle">
		  		<el-input v-model="form.fTitle" placeholder="请填写任务标题" />
		  	</el-form-item>
		  	<el-form-item label="任务摘要" prop="fResume">
		  		<el-input v-model="form.fResume" placeholder="请填写任务摘要" />
		  	</el-form-item> -->
		  <!-- </el-form> -->
		  <!-- <div v-for="item in tableData" style="line-height: 2;font-size: 16px;">
		  	<span v-if="item.FEndBalance" style="letter-spacing: 2px;margin-right: 10px;">{{ item.FItem }}:</span><span v-if="item.FEndBalance">{{ item.FEndBalance }}</span>
		  </div> -->
		  <span slot="footer" class="dialog-footer">
		    <el-button v-if="flag" @click="lateClose">取 消</el-button>
		    <el-button v-if="flag" type="primary" @click="latesubmit">注销</el-button>
			<el-button v-if="!flag" type="primary" @click="lateClose">不满足注销条件，关闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import parm from '@/components/loyout/publicList'
	export default
	{
		data(){
			return{
				byecontent:'',
				latecontent:'此操作将永久注销该账号下的相关数据, 是否继续?',
				showconfirmtext:'',
				clock:10,
				intervals:"",
				dialogVisible:false,
				lateVisible:false,
				clearstatus:1,
				items:[],
				selectId: '',
				ismanager:'',
				showList:[],//展示账号上的信息
				tableLabel:[
					{
						prop: 'FItem',
						label: '项目',
						align: 'center',
						width: '130',
						type: 'string'
					},{
						prop: 'FEndBalance',
						label: '金额/描述',
						align: 'center',
						width: 'auto',
						type: 'string'
					}],
				tableData:[],
				flag : true,
			}
		},
		computed: {
			...mapGetters(['getUserInfo','getThemeName','getMenPList']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			// console.log(this.getUserInfo,'7777777')
			if(this.getUserInfo != null){
				this.ismanager = this.getUserInfo.fIsManager
			}
			
		},
		created() {
			this.getMenList();
		},
		methods:{
			getUrl(e){
				this.selectId = e.FApplicationID;
				this.$emit('getUrl')
			},
			getMenList(){
				this.ApiRequestPostNOMess('api/mall/sys/application/get-user-app-all-child-by-app-name',{
					  "fApplicationName": "个人中心",
					  "appTypeId": this.fAppTypeID,
					  "fSubsystemID":120
				}).then(
					res=>{
						console.log(JSON.parse(res.obj))
						if(JSON.parse(res.obj).length>0){
							let menArr = [];
							menArr = this.toMenTree(JSON.parse(res.obj));
							let menArr2  = [];
							menArr2 = menArr[0].children;
							this.items = menArr2;
						}	
						this.getquest()
					},
					error =>{						
					}
				);
			
			},
			getquest(){
				this.ApiRequestPostNOMess('api/mall/auxiliary/questionnaire/get-curret-questionnaire')
				.then(
					res=>{
						console.log(res)
						if(res.obj){
						res.obj.FApplicationText = '问卷调查'
						res.obj.FApplicationName = '/QuestionnaireInvestigation'
						this.items[0].children.push(res.obj)	
						}else{
							return
						}
					},
					error =>{						
					}
				);
			},
			//注销账户
			cancellationOpen(e){
				if(e == 1){
					this.dialogVisible = false,
					console.log("e=====1")
					clearInterval(this.intervals)
					this.clock = 10
					this.clearstatus = 1
					return
				}
				this.showconfirmtext="下一步",
				this.dialogVisible = true
				this.byecontent = "注销账户后，您当前账号将不再能够登录以及使用聚材通平台，您确定要注销么？";
				this.showconfirmtext = "下一步"+"（"+this.clock+"）"
				var that = this
				that.intervals = setInterval(function () {
					that.clock--
					that.showconfirmtext = "下一步"+"（"+that.clock+"）"
					console.log(that.showconfirmtext,'----------------------------')
					that.$forceUpdate()
					if (that.clock < 1) {     //当倒计时小于0时清除定时器
					  clearInterval(that.intervals)
					  that.showconfirmtext = "下一步"
					  that.clock = 10
					  that.clearstatus = 2
					  }
					  
				},1000)
			},
			// 退出登录
			logout() {
				this.visible = false;
				// 清空本地登录信息
				// localStorage.setItem("user", "");
				localStorage.clear();
				sessionStorage.clear();
				// 清空vuex登录信息
				this.$store.commit("setUser", null);
				this.$store.commit("setCarCount", 0);
				this.$store.commit("setUserInfo", null);
				this.$store.commit("setMenBList", null);
				this.$store.commit("setMenPList", null);
				// console.log(this.username,"username");
				// this.$router.push('./login')
				// this.notifySucceed("成功退出登录");
			},
			handleClose(){
				this.cancellationOpen(1)
			},
			lateClose(){
				this.lateVisible = false
			},
			nextbu(){
				//当this.ismanager不是1时，代表本次登录的账户是子账户（提示一次就可以进行注销）
				if(this.ismanager !== 1){
					if(this.clearstatus == 1){
					}else if(this.clearstatus == 2 && this.showconfirmtext == "下一步"){
						this.dialogVisible = false,
						clearInterval(this.intervals)
						this.clock = 10
						this.clearstatus = 1
						this.uModelType == '注销'
						this.ApiRequestPut('/api/mall/sys/identity-users/cancel-account').then(
							res => {
								if(res.obj == ''){
									  this.$message({
									          message: res.message,
									          type: 'success'
									        });
										this.$router.push('/login')
										this.logout()
								}else{
									this.$message({
									        message: res.obj,
									        type: 'warning'
									      });
								}
							},
							error => {
						
							}
						);
					}
				}else{
					// 如果是第一步，并且
					if(this.clearstatus == 1){
						//第一次确认，如果进入这个方法的话就直接拦截
						
					}else if(this.clearstatus == 2 && this.showconfirmtext == "下一步"){
						this.showconfirmtext = "确认"+"（"+this.clock+"）"
						// 第二次确认 如果进入这个方法的话就代表第一次读秒结束了
						var that = this
						this.byecontent = "注销账户后，您将失去本平台一切会员权益资格，您的剩余积分、金币、资金将无法继续使用，您确定要注销么？";
						that.intervals = setInterval(function () {
							that.clock--
							that.showconfirmtext = "确认"+"（"+that.clock+"）"
							console.log(that.showconfirmtext,'+++++++')
							if (that.clock < 1) {     //当倒计时小于0时清除定时器
							  clearInterval(that.intervals)
							  that.showconfirmtext = "确认"
							  that.clock = 10
							  that.clearstatus = 3
							  }
						},1000)
						
					}else if(this.clearstatus == 2 && this.showconfirmtext != "下一步"){
						return;
					}else{
						// 注销方法
						//确认  第二次读秒完成   执行注销方法，再把下面的代码执行一下、
						this.dialogVisible = false,
						clearInterval(this.intervals)
						this.clock = 10
						this.clearstatus = 1
						this.uModelType == '注销'
						//调取新接口，根据查看返回的数据进行处理（1.无返回值，直接调注销接口 2.有返回值查看第一条数据 iscancel。是0，则展示数据，不可删除；）
						this.ApiRequestPostNOMess('/api/mall/sys/identity-users/cancelation-customer-account-no-compute').then(
							res => {
								console.log(res)
								//当没有任何返回值时，没啥好说的，直接调用注销的接口
								if(res.obj == '' || res.obj == '[]'){
									 this.killaccount()
								}else{
									this.lateVisible = true
									this.showList = JSON.parse(res.obj)
									// this.tableLabel = []
									this.tableData = this.showList
									this.flag = this.tableData[0].FIsCancel == 0 ? false : true
									// var obj = new Object();
									// for(let item of this.showList){
									// 	this.tableLabel.push({
									// 		label:item.FItem,
									// 		width: item.FItem == '未提货的派车单' ? 'auto' : '115',
									// 		prop:item.FItem,
									// 		align: 'center'
									// 	})
									// 	obj[item.FItem] = item.FEndBalance
									// }
									// this.tableData.push(obj)
									
								}
							},
							error => {
							}
						);
						return
					}
					
				}
			
			},
			//点击询问弹框的确定按钮
			latesubmit(){
				if(this.showList.length >0){
					if(this.showList[0].FIsCancel == 0){
						this.lateVisible = false
						this.flag = false
					}else{
						this.lateVisible = false
						//当this.showList[0].FIsCancel == 1时，直接调取注销接口即可
						this.killaccount()
					}
				}
			},
			//注销接口的方法
			killaccount(){
				this.ApiRequestPutNOMess('/api/mall/sys/identity-users/cancel-account').then(
					res => {
						// console.log(res,'111')
						if(res.obj == ''){
							  this.$message({
							          message: res.message,
							          type: 'success'
							        });
								this.$router.push('/login')
								this.logout()
						}else{
							this.$message({
							        message: res.obj,
							        type: 'warning'
							      });
						}
					},
					error => {
				
					}
				);
			},
		}
		
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	.ma-botn .border:last-child{
		border-bottom: 1px solid #f2f2f2;
	}
	.ma-botn{
		margin-top: 10px;
		margin-right:13px; 
		background-color: #fff;
	}
	
	.border{
		
		border-left: 1px solid #f2f2f2;
		border-right: 1px solid #f2f2f2;
		border-top: 1px solid #f2f2f2;
		width:142px;
		height:auto; 
	}
	.titlefont{
		width: 65px;
		height: 19px;
		font-size: 16px;
	
		font-weight: bold;
	}
	.padding-13{
		padding: 13px;
	}
	.pading-l-30{
		font-size: 14px;
		padding-left:17px;
		margin-top: 10px;
		cursor: pointer;
	}
	/deep/  .el-dialog__body{
		padding: 10px  20px;
	}
</style>
